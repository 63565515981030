import React from "react";
import { Table } from "../../components/table/Table";
import { Attributes } from "../../interfaces";
import { AttributeRow } from "./AttributeRow";

interface AttributeTableProps {
  attributes: Attributes;
}

export default function AttributeTable({ attributes }: AttributeTableProps) {
  return (
    <Table variant="bordered">
      {Object.entries(attributes).map(([key, value], i) => (
        <AttributeRow key={i} attribute={{ key, value }} />
      ))}
    </Table>
  );
}
