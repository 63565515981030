import jwt_decode from "jwt-decode";
import { useState } from "react";
import { Token, User } from "../interfaces";

type useUserType = {
  user: User | null;
  setUser: (user: User | null) => void;
};

export const useUser = (): useUserType => {
  const getUser = (): User | null => {
    const userString = localStorage.getItem("user");
    if (userString) {
      return JSON.parse(userString);
    }
    return null;
  };

  const [user, setUser] = useState<User | null>(getUser());

  const saveUser = (user: User | null) => {
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  };

  return {
    user,
    setUser: saveUser,
  };
};

export const tokenIsValid = (_token = ""): boolean => {
  const token =
    _token.length > 0 ? _token : (JSON.parse(localStorage.getItem("user") ?? '{"token": ""}')["token"] as string);

  const decoded = jwt_decode(token) as Token;
  return new Date().getTime() / 1000 <= decoded.exp;
};
