import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import BasicLink from "../components/link/BasicLink";
import List from "../components/list/List";
import ListItem from "../components/list/ListItem";
import { PageContainer } from "../components/page/PageContainer";
import PageHeader from "../components/page/PageHeader";
import Heading from "../components/typography/Heading";
import { Company } from "../interfaces";
import CompanyNodes from "./CompanyNodes";
import WhitelistedAttributes from "./WhitelistedAttributes";
import CompanySoupSummary from "./CompanySoupSummary";

export default function ViewCompany({ company }: { company: Company }) {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/whitelisted-attributes`}>
        <WhitelistedAttributes company={company} />
      </Route>
      <Route path={`${path}/nodes`}>
        <CompanyNodes company={company} />
      </Route>
      <Route path={`${path}/soup`}>
        <CompanySoupSummary company={company} />
      </Route>

      <Route exact path={path}>
        <PageContainer header={<PageHeader left={<Heading type="h1">{company.name}</Heading>} />}>
          <List _style="bordered">
            <ListItem>
              <BasicLink to={{ pathname: `${url}/nodes` }}>
                <Heading type="h3">Nodes</Heading>
              </BasicLink>
            </ListItem>
            <ListItem>
              <BasicLink to={{ pathname: `${url}/whitelisted-attributes` }}>
                <Heading type="h3">Whitelisted attributes</Heading>
              </BasicLink>
            </ListItem>
            <ListItem>
              <BasicLink to={{ pathname: `${url}/soup` }}>
                <Heading type="h3">Soup summary</Heading>
              </BasicLink>
            </ListItem>
          </List>
        </PageContainer>
      </Route>
    </Switch>
  );
}
