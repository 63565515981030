import React from "react";
import { BaseProps } from "../core/baseTypes";
import { useStyles } from "../core/useStyles";
import styles from "./Site.module.css";

interface SiteContainerProps extends BaseProps {
  centerBoth?: boolean;
  noBackground?: boolean;
}

const SiteContainer = ({ children, centerBoth = false, noBackground = false }: SiteContainerProps): JSX.Element => {
  const cx = useStyles(styles);
  return (
    <div
      className={cx({
        siteContainer: [{ center: centerBoth }, { transparent: noBackground }],
      })}
    >
      <div className={styles.inner}>{children}</div>
    </div>
  );
};

export default SiteContainer;
