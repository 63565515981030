import React from "react";
import { TableCell, TableRow } from "../../components/table/Table";
import { JSONValue } from "../../interfaces";
import isValidURL from "../../lib/isURL";
import { attributeValueToString } from "../../lib/node";

interface AttributeRowProps {
  attribute: { key: string; value: JSONValue };
}

export function AttributeRow({ attribute: { key, value } }: AttributeRowProps) {
  const urlify = (x: string) =>
    isValidURL(x) ? (
      <>
        <a href={x}>{x}</a>{" "}
      </>
    ) : (
      x
    ); // Sorry about the extra space

  const stringValues = (Array.isArray(value) ? value : [value]).map(attributeValueToString);
  const valueToDisplay = stringValues.map(urlify);

  return (
    <TableRow>
      <TableCell>{key}</TableCell>
      <TableCell>{valueToDisplay}</TableCell>
    </TableRow>
  );
}
