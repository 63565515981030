import { EffectCallback, DependencyList, useRef, useEffect } from "react";

/**
 * Exactly like React's `useEffect` but won't trigger on the first invocation.
 */
export default function useEffectSkipFirst(effect: EffectCallback, deps?: DependencyList | undefined) {
  const ref = useRef(false);

  useEffect(() => {
    if (ref.current) effect();
    else ref.current = true;
  }, deps);
}
