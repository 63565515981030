import React from "react";
import StyledExpandable from "../components/expandable/StyledExpandable";
import Item from "../components/item/Item";
import { Annotation, FromEdge, Attributes } from "../interfaces";
import { getStringValue, parseAnnotations } from "../lib/node";
import AnnotatedImage from "./AnnotatedImage";

interface stateInterface {
  framedContentAttributes: Attributes;
  hidden: boolean;
  annotations: Annotation[];
}

export default class FramedContent extends React.Component<FromEdge, stateInterface> {
  constructor(props: FromEdge) {
    super(props);

    this.state = {
      framedContentAttributes: props.from.attributes,
      annotations: parseAnnotations(props.from.attributes["annotations"]),
      hidden: true,
    };
  }

  render(): JSX.Element {
    return (
      <Item>
        <StyledExpandable
          title={getStringValue("label", this.state.framedContentAttributes)}
          content={
            getStringValue("content_type", this.state.framedContentAttributes) === "video" ? (
              <video controls>
                <source src={getStringValue("url", this.state.framedContentAttributes)} />
              </video>
            ) : (
              <AnnotatedImage
                url={getStringValue("url", this.state.framedContentAttributes) ?? ""}
                annotations={this.state.annotations}
              />
            )
          }
        />
      </Item>
    );
  }
}
