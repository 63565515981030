import React, { useEffect, useRef, useState } from "react";
import { Annotation } from "../../interfaces";

interface propInterface {
  edge_id: number;
  url: string;
  annotations: Annotation[];
  label: string;
  onClick: (x: number, y: number) => void;
}

const AnnotatorEditor = (props: propInterface): JSX.Element => {
  const [imageHeight, setImageHeight] = useState(1);
  const width = 572;
  const height = 572;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const drawAnnotations = (annotations: Annotation[], imgHeight: number) => {
    const canvas = canvasRef.current as HTMLCanvasElement;
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

    if (ctx === null) {
      return;
    }

    annotations.forEach((element) => {
      const x = width * element.x,
        y = imgHeight * element.y;
      ctx.beginPath();
      ctx.fillStyle = "rgba(0,0,0,0.65)";
      ctx.arc(x, y, 7, 0, 2 * Math.PI);
      ctx.fill();

      ctx.beginPath();
      ctx.arc(x, y, 4, 0, 2 * Math.PI);
      ctx.fillStyle = "white";
      ctx.fill();

      ctx.fillStyle = "rgba(0,0,0,0.65)";
      ctx.fillRect(x - 20, y - 35, ctx.measureText(element.title).width + 15, 25);

      ctx.fillStyle = "white";
      ctx.font = "18px Arial";
      ctx.fillText(element.title.trim(), x - 15, y - 15);
    });
  };

  useEffect(() => {
    const img = new Image();
    const canvas = canvasRef.current as HTMLCanvasElement;
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

    img.onload = () => {
      const imgHeight = (img.height / img.width) * width;
      setImageHeight(imgHeight);
      canvas.height = imgHeight;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, width, imgHeight);
      drawAnnotations(props.annotations, imageHeight);
    };

    img.src = props.url;
  }, [props.annotations, imageHeight, props.url]);

  const clickScreen = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current as HTMLCanvasElement;
    const rect = canvas.getBoundingClientRect();

    const x = e.clientX - rect.left,
      y = e.clientY - rect.top;
    props.onClick(x / width, y / imageHeight);
  };

  return (
    <div className="imageViewer">
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        id={`canvas_${props.edge_id}`}
        onMouseDown={clickScreen}
      ></canvas>
      <img ref={imgRef} src={props.url} style={{ display: "none" }} alt="Annotator" />
    </div>
  );
};
export default AnnotatorEditor;
