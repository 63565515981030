import {
  createAction,
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarResults,
  KBarSearch,
  useDeepMatches,
  useRegisterActions,
} from "kbar";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Node } from "../interfaces";
import { getStringValue } from "../lib/node";
import { ProductDatabaseConnection } from "./ProductDatabaseConnection";

interface Props {
  searchKey: string;
}

const KBarPortalModal = ({ searchKey }: Props): JSX.Element => {
  const [searchValue, setSearchValue] = useState("");
  const [queriedProducts, setQueriedProducts] = useState<Node[]>([]);

  const searchStyle = {
    padding: "12px 16px",
    fontSize: "16px",
    width: "100%",
    boxSizing: "border-box" as React.CSSProperties["boxSizing"],
    outline: "none",
    border: "none",
    background: "#eee",
    color: "#333",
  };
  const animatorStyle = {
    maxWidth: "600px",
    width: "100%",
    background: "#eee",
    color: "#333",
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0px 6px 20px rgb(0 0 0 / 20%)",
    zIndex: 2,
  };

  const itemStyle = (active: boolean) => ({
    padding: "12px 16px",
    background: active ? "#ddd" : "transparent",
    borderLeft: `2px solid ${active ? "#333" : "transparent"}`,
    display: "flex",
    alignItems: "center",
    gap: "8px",
    // justifyContent: "space-between",
    cursor: "pointer",
  });

  const ResultsRow = () => {
    const { results } = useDeepMatches();

    return (
      <KBarResults
        items={results}
        onRender={({ item, active }) => {
          return (
            <div style={itemStyle(active)}>
              {typeof item != "string" ? <span style={{ color: "gray" }}>{item.icon}</span> : ""}
              {typeof item === "string" ? item : item.name}
            </div>
          );
        }}
      />
    );
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value);
  };

  useEffect(() => {
    if (searchValue.length) {
      ProductDatabaseConnection.getSimpleProductListByManufacturerArticleNumber(
        10,
        searchValue,
        1,
        false,
        searchKey
      ).then((res) => setQueriedProducts(res));
    }
  }, [searchValue, searchKey]);

  useRegisterActions(
    queriedProducts.map((product) =>
      createAction({
        name:
          getStringValue(searchKey, product.attributes) ??
          getStringValue("manufacturer_article_number", product.attributes) ??
          "",
        parent: "search",
        section: "Results",
        perform: () => (window.location.pathname = `products/show/${product.id}`),
      })
    ),
    [queriedProducts]
  );

  useRegisterActions(
    searchValue
      ? [
          {
            id: "edit_node",
            name: `Edit node ${searchValue}`,
            shortcut: ["s"],
            parent: "edit",
            keywords: "edit product node",
            perform: () => (window.location.pathname = `products/edit/${searchValue}`),
          },
        ]
      : [],
    [searchValue]
  );

  return (
    <KBarPortal>
      <KBarPositioner className="kbarPositioner">
        <KBarAnimator style={animatorStyle}>
          <KBarSearch style={searchStyle} onChange={handleChange} value={searchValue} />
          <ResultsRow />
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  );
};

export default KBarPortalModal;
