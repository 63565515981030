import React, { useEffect, useState } from "react";
import Loading from "../components/loading/Loading";
import { PageContainer } from "../components/page/PageContainer";
import PageHeader from "../components/page/PageHeader";
import Heading from "../components/typography/Heading";
import { useLoadingState } from "../hooks/LoadingHook";
import List from "../components/list/List";
import { Company, HTTPResult, SDMTake } from "../interfaces";
import { ProductDatabaseConnection } from "./ProductDatabaseConnection";
import SDMClient from "./SDMClient";
import ListItem from "../components/list/ListItem";
import BasicLink from "../components/link/BasicLink";
import ListItemActions from "../components/list/ListItemActions";

const LoadingPage = ({ companyName }: { companyName: string }) => (
  <PageContainer header={<PageHeader left={<Heading type="h1">{companyName} - Soup Summary</Heading>} />}>
    <Loading />
  </PageContainer>
);

interface NodeSoupSummary {
  nodeId: number;
  soupTakes: SDMTake[] | null;
}

function parseSDMResult(result: HTTPResult<SDMTake[]>) {
  if (result._kind === "success") return result.result;
  if (result.code === 404) return null;
  throw Error(result.message);
}

async function getSoupSummary(companyId: number): Promise<NodeSoupSummary[]> {
  const nodeAssociations = await ProductDatabaseConnection.getCompanyNodeAssociations(
    { companyId },
    { limit: 1000, offset: 0 }
  );
  const companyNodeIds = nodeAssociations.map((nodeAssociation) => nodeAssociation.node_id);
  const recordings = await Promise.all(
    companyNodeIds.map((nodeId) =>
      SDMClient.getTakesForNodeID(nodeId)
        .then(parseSDMResult)
        .then((soupTakes) => ({ nodeId, soupTakes }))
    )
  );
  return recordings;
}

function SoupNodeSummaryItem(node: NodeSoupSummary) {
  const types = new Set(node.soupTakes?.map((take) => take.data.type));
  return (
    <ListItem key={node.nodeId}>
      <BasicLink
        to={{
          pathname: `/products/show/${node.nodeId}`,
        }}
      >
        <Heading type="h3">{node.nodeId}</Heading>
      </BasicLink>
      <ListItemActions>
        <span style={{ width: "2em", paddingLeft: "1em" }}>{types.has("cut") && "cut"}</span>
        <span style={{ width: "2em", paddingLeft: "1em" }}>{types.has("nat") && "nat"}</span>
        <span style={{ width: "2em", paddingLeft: "1em" }}>{types.has("test") && "test"}</span>
      </ListItemActions>
    </ListItem>
  );
}

export default function CompanySoupSummary({ company }: { company: Company }) {
  const { loadingState, setIsLoading, setIsLoaded, setLoadingError } = useLoadingState();
  const [soupSummary, setSoupSummary] = useState<NodeSoupSummary[]>();

  useEffect(() => {
    setIsLoading();
    getSoupSummary(company.id).then(setSoupSummary).then(setIsLoaded).catch(setLoadingError);
  }, []);

  if (loadingState.isError) return <>{loadingState.errorMessage}</>;
  if (loadingState.isLoading) return <LoadingPage companyName={company.name} />;

  return (
    <PageContainer header={<PageHeader left={<Heading type="h1">{company.name} - Soup summary</Heading>} />}>
      <List _style="bordered">{soupSummary?.map(SoupNodeSummaryItem)}</List>
    </PageContainer>
  );
}
