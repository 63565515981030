import { useEffect, useRef } from "react";

/**
 * Returns a function to run the `callback` after `delayInMilliseconds`.
 * If called again before `delayInMilliseconds` have passed,
 * the timeout will be reset and started anew.
 */
export default function useDelayedFunction<T>(callback: (args: T) => void, delayInMilliseconds: number) {
  const ref = useRef<number>();
  const savedCallback = useRef<(args: T) => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  function callDelayed(args: T) {
    window.clearTimeout(ref.current);
    ref.current = window.setTimeout(() => {
      if (savedCallback.current) savedCallback.current(args);
    }, delayInMilliseconds);
  }

  return callDelayed;
}
