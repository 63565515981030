import React, { ChangeEvent, KeyboardEvent } from "react";
import { onChangeType } from "../../interfaces";
import Input from "./Input";
import styles from "./Input.module.css";

interface AutocompleteProps {
  onChange: onChangeType;
  placeholder: string;
  name: string;
  value: string;
  suggestions: string[];
}

interface AutocompleteState {
  activeSuggestion: number;
  allSuggestions: Array<string>;
  filteredSuggestions: Array<string>;
  showAutocomplete: boolean;
  value: string;
}

class AutocompleteInput extends React.Component<AutocompleteProps, AutocompleteState> {
  constructor(props: AutocompleteProps) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      activeSuggestion: 0,
      allSuggestions: this.props.suggestions,
      filteredSuggestions: [],
      showAutocomplete: false,
      value: props.value,
    };
  }

  handleInputChange(e: ChangeEvent<HTMLInputElement>): void {
    const userInput = e.currentTarget.value;

    const filtered = this.state.allSuggestions.filter(
      (suggestion) => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: filtered,
      showAutocomplete: true,
      value: e.target.value,
    });

    this.props.onChange(e.target.name, e.target.value);
  }

  onClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    this.setState({
      activeSuggestion: 0,
      showAutocomplete: false,
      value: event.currentTarget.innerText,
    });
    this.props.onChange(this.props.name, event.currentTarget.innerText);
  };

  onKeyDown = (e: KeyboardEvent<HTMLElement>): void => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    let shouldPreventDefault = true;

    switch (e.key) {
      case "Enter":
        if (!filteredSuggestions.length) break;
        this.setState({
          activeSuggestion: 0,
          showAutocomplete: false,
          value: filteredSuggestions[activeSuggestion],
        });
        this.props.onChange(this.props.name, filteredSuggestions[activeSuggestion]);
        break;
      case "ArrowUp":
        if (activeSuggestion === 0) {
          return;
        }
        this.setState({ activeSuggestion: activeSuggestion - 1 });
        break;
      case "ArrowDown":
        if (activeSuggestion >= filteredSuggestions.length - 1) {
          return;
        }
        this.setState({ activeSuggestion: activeSuggestion + 1 });
        break;
      case "Tab":
        shouldPreventDefault = false;
        this.setState({
          activeSuggestion: 0,
          showAutocomplete: false,
        });
        break;
      default:
        shouldPreventDefault = false;
        break;
    }

    if (shouldPreventDefault) {
      e.preventDefault();
    }
  };

  componentDidUpdate(prevProps: AutocompleteProps): void {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  render(): JSX.Element {
    const {
      handleInputChange,
      onClick,
      onKeyDown,
      state: { activeSuggestion, filteredSuggestions, showAutocomplete, value },
    } = this;

    let suggestionsComponent;

    if (showAutocomplete && value) {
      if (filteredSuggestions.length) {
        suggestionsComponent = (
          <div className={styles.autocompleteResults}>
            {filteredSuggestions.map((suggestion, index) => {
              return (
                <div
                  className={`${styles.result} ${index === activeSuggestion ? styles.active : ""}`}
                  key={suggestion}
                  onClick={onClick}
                >
                  {suggestion}
                </div>
              );
            })}
          </div>
        );
      } else {
        suggestionsComponent = "";
      }
    }

    return (
      <div className={styles.autocompleteContainer}>
        <Input
          autoComplete="off"
          name={this.props.name}
          onChange={handleInputChange}
          onKeyDown={onKeyDown}
          placeholder={this.props.placeholder}
          value={this.state.value}
        />

        {suggestionsComponent}
      </div>
    );
  }
}

export default AutocompleteInput;
