import { Location } from "history";
import React, { Fragment } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import CreateEdge from "./CreateEdge";
import DeleteEdge from "./DeleteEdge";
import DeleteProduct from "./DeleteProduct";
import EditProduct from "./EditProduct";
import ProductList from "./ProductList";
import ShowProduct from "./ShowProduct";

interface LocationState {
  background?: Location;
}

export type RouteType = RouteComponentProps<{ id: string } | Record<string, never>, StaticContext, LocationState>;

function ProductRouter(props: RouteType): JSX.Element {
  const match = useRouteMatch();

  const background = props.location.state && props.location.state.background;

  return (
    <Fragment>
      <Switch location={background || props.location}>
        <Route path={`${match.url}/edit/:id`} component={EditProduct}></Route>
        <Route path={`${match.url}/edit/`} component={EditProduct}></Route>

        <Route path={`${match.url}/`} render={(props) => <ProductList routeProps={props} />}></Route>
      </Switch>
      <Route
        path={`/products/show/:id`}
        render={(modalProps) => {
          return <ShowProduct routeProps={props} id={parseInt(modalProps.match.params.id)} />;
        }}
      />
      <Route
        path={`/products/delete/:id`}
        render={(modalProps) => {
          return <DeleteProduct routeProps={props} id={parseInt(modalProps.match.params.id)} />;
        }}
      />
      <Route
        path={`/products/edit/:id/create_edge?direction=from`}
        render={(modalProps) => {
          return <CreateEdge routeProps={props} id={parseInt(modalProps.match.params.id)} direction="from" />;
        }}
      />
      <Route
        path={`/products/edit/:id/create_edge?direction=to`}
        render={(modalProps) => {
          return <CreateEdge routeProps={props} id={parseInt(modalProps.match.params.id)} direction="to" />;
        }}
      />
      <Route
        path={`/products/edit/:id/delete_edge/:edge_id`}
        render={(modalProps) => {
          return (
            <DeleteEdge
              routeProps={props}
              node_id={parseInt(modalProps.match.params.id)}
              edge_id={parseInt(modalProps.match.params.edge_id)}
            />
          );
        }}
      />
    </Fragment>
  );
}

export default ProductRouter;
