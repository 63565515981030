import React, { useState } from "react";
import { Button } from "../components/button/Button";
import { ButtonGroup } from "../components/button/ButtonGroup";
import Item from "../components/item/Item";
import Loading from "../components/loading/Loading";
import ModalPage, { GenericModalProps } from "../components/modal/ModalPage";
import { ProductDatabaseConnection } from "./ProductDatabaseConnection";

interface DeleteEdgeProps extends GenericModalProps {
  node_id: number;
  edge_id: number;
}

const DeleteEdge = ({ node_id, edge_id, routeProps }: DeleteEdgeProps): JSX.Element => {
  const [edgeDeleted, setEdgeDeleted] = useState(false);

  const deleteEdge = () => {
    ProductDatabaseConnection.deleteEdge(edge_id)
      .then(() => {
        setEdgeDeleted(true);
      })
      .catch((reason) => console.warn(reason));
  };

  if (edgeDeleted) {
    window.location.replace(`/products/edit/${node_id}`);
  }

  return (
    <ModalPage routeProps={routeProps} title={`Deleting edge ${edge_id}`}>
      {edge_id ? (
        <>
          <Item>You are about to delete the edge: {edge_id}</Item>
          <ButtonGroup align="right">
            <Button variant="negative" onClick={deleteEdge}>
              Delete
            </Button>
            <Button variant="secondary" onClick={() => routeProps.history.goBack()}>
              Cancel
            </Button>
          </ButtonGroup>
        </>
      ) : (
        <Loading />
      )}
    </ModalPage>
  );
};

export default DeleteEdge;
