import React from "react";
import styles from "./Page.module.css";

interface PageHeaderProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
}

const PageHeader = ({ left, right }: PageHeaderProps): JSX.Element => {
  return (
    <div className={styles.pageHeader}>
      <div className={styles.left}>{left}</div>
      <div className={styles.right}>{right}</div>
    </div>
  );
};

export default PageHeader;
