import { useEffect, useState } from "react";
import { useLoadingState } from "./LoadingHook";
import usePagination from "./PaginationHook";
import useEffectSkipFirst from "./useEffectSkipFirst";

export function getQueryFromSearch(search: string): string {
  const urlSearchParams = new URLSearchParams(search);
  return urlSearchParams.get("query") ?? "";
}

/**
 * Hook to abstract away pagination and filtering/searching/querying for an API list resource.
 * Fetches to the API are automatically made when the page, pageSize and query
 * variables are changed. When a new query is set, the page is automatically reset
 * to one.
 */
export function usePaginatedAPIQuery<T>(
  loader: (page: number, pageSize: number, query: string) => Promise<T[]>,
  initialPage = 1,
  initialPageSize = 25,
  initialQuery = ""
) {
  const [_flipMeToTriggerRefresh, _setFlipMeToTriggerRefresh] = useState(false); // Use this to trigger loadPage
  const { page, pageSize, nextPage, prevPage, setPage } = usePagination({ initialPage, initialPageSize });
  const [query, setQuery] = useState(initialQuery);
  const [pageItems, setPageItems] = useState<T[]>([]);
  const { loadingState, setIsLoaded, setIsLoading, setLoadingError, setLoadingState } = useLoadingState(false);

  function loadPage(page: number, pageSize: number, query: string) {
    setIsLoading();
    loader(page, pageSize, query).then(setPageItems).then(setIsLoaded).catch(setLoadingError);
  }

  // can't invoke loadPage directly incase user of hook tried to update the page/query
  const refresh = () => _setFlipMeToTriggerRefresh((v) => !v);

  // Reset page to initial page when we have a new query (and let automatic fetching occur)
  // or manually fetch with new query (if we are already on page 1 and autmatic fetching won't kick in)
  const onNewQuery = () => {
    if (page === 1) refresh();
    else setPage(1);
  };

  useEffect(() => loadPage(page, pageSize, query), [page, pageSize, _flipMeToTriggerRefresh]);
  useEffectSkipFirst(onNewQuery, [query]);

  return {
    pagination: { page, pageSize, nextPage, prevPage, setPage },
    loading: { loadingState, setIsLoaded, setIsLoading, setLoadingError, setLoadingState },
    /** The items on the currently loaded page */
    pageItems,
    /** Fetch current page with current query. Used for example if it is know that changes
     * have been made to the underlying resource */
    refresh,
    /** The current query sent to the API */
    query: { query, setQuery },
  };
}
