import React from "react";
import { useStyles } from "../core/useStyles";
import styles from "./Table.module.css";

interface BaseTableProps {
  children: React.ReactNode;
}

interface TableProps extends BaseTableProps {
  variant?: "bordered" | "regular";
  fluid?: boolean;
}

export const Table = ({ children, variant = "regular", fluid = false }: TableProps): JSX.Element => {
  const cx = useStyles(styles);

  return (
    <table
      className={cx({
        table: [variant, { fluid }],
      })}
    >
      {children}
    </table>
  );
};

export const TableRow = ({ children }: BaseTableProps): JSX.Element => {
  return <tr className={styles.row}>{children}</tr>;
};

export const TableCell = ({ children }: BaseTableProps): JSX.Element => {
  return <td className={styles.cell}>{children}</td>;
};

export const TableHeader = ({ children }: BaseTableProps): JSX.Element => {
  return <th className={styles.header}>{children}</th>;
};
