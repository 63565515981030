import React, { useState } from "react";
import Checkbox from "../components/checkbox/Checkbox";
import Expandable from "../components/expandable/Expandable";
import SubHeader from "../components/header/SubHeader";
import Item from "../components/item/Item";
import List from "../components/list/List";
import ListItem from "../components/list/ListItem";
import ListItemActions from "../components/list/ListItemActions";
import Loading from "../components/loading/Loading";
import Heading from "../components/typography/Heading";
import { useLoadingState } from "../hooks/LoadingHook";
import { NodeWithEdges, ToOrFromEdge, Node } from "../interfaces";
import { attributesDifference, getStringValue, getTitle } from "../lib/node";
import AttributeTable from "./attributes/AttributeTable";
import { ProductDatabaseConnection } from "./ProductDatabaseConnection";

interface NodesWithEdges {
  companyNodeWithEdges: NodeWithEdges;
  fullNodeWithEdges: NodeWithEdges;
}

interface NodesWithEdgesAndToggle extends NodesWithEdges {
  toggleEdgeAndNodeVisibility: (nodeId: number, edgeId: number, isVisible: boolean) => void;
}

function AttributesSection({ companyNodeWithEdges, fullNodeWithEdges }: NodesWithEdges) {
  const diff = attributesDifference(fullNodeWithEdges.node.attributes, companyNodeWithEdges.node.attributes);
  let hiddenAttributesSection = null;
  if (Object.keys(diff).length)
    hiddenAttributesSection = (
      <Item>
        <SubHeader left={<Heading type="h2">Hidden attributes</Heading>} />
        <AttributeTable attributes={diff} />
      </Item>
    );

  return (
    <>
      <Item>
        <SubHeader left={<Heading type="h2">Attributes</Heading>} />
        <AttributeTable attributes={companyNodeWithEdges.node.attributes} />
      </Item>
      {hiddenAttributesSection}
    </>
  );
}

function DoubleColumn({ children }: { children: [JSX.Element, JSX.Element] }) {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ width: "100%" }}>{children[0]}</div>
      <div style={{ width: "5em" }} />
      <div style={{ width: "100%" }}>{children[1]}</div>
    </div>
  );
}

function EdgesSection({
  companyNodeWithEdges,
  fullNodeWithEdges,
  toggleEdgeAndNodeVisibility,
}: NodesWithEdgesAndToggle) {
  // Since edge ids are unique we don't have to care about directionality when deciding if it belongs to the company
  const companyNodeEdgeIds = [...companyNodeWithEdges.incoming_edges, ...companyNodeWithEdges.outgoing_edges].map(
    (x) => x.id
  );

  const Edge = (edge: ToOrFromEdge) => {
    const isVisible = companyNodeEdgeIds.includes(edge.id);
    const otherNode = "from" in edge ? edge.from : edge.to;
    return (
      <ListItem key={edge.id} image={{ url: getStringValue("thumbnail", edge.attributes) }}>
        {getStringValue("type", edge.attributes) + "  " + getTitle(otherNode)}
        <ListItemActions>
          <Checkbox
            checked={isVisible}
            label={"Visible"}
            onClick={() => toggleEdgeAndNodeVisibility(otherNode.id, edge.id, isVisible)}
          />
        </ListItemActions>
      </ListItem>
    );
  };

  return (
    <DoubleColumn>
      <>
        <SubHeader left={<Heading type="h2">Incoming edges</Heading>} />
        <List _style="alternating">{fullNodeWithEdges.incoming_edges.map(Edge)}</List>
      </>
      <>
        <SubHeader left={<Heading type="h2">Outgoing edges</Heading>} />
        <List _style="alternating">{fullNodeWithEdges.outgoing_edges.map(Edge)}</List>
      </>
    </DoubleColumn>
  );
}

function CompanyNodeContent({
  companyNodeWithEdges,
  fullNodeWithEdges,
  toggleEdgeAndNodeVisibility,
}: NodesWithEdgesAndToggle) {
  return (
    <>
      <Heading type="h3">{getTitle(fullNodeWithEdges.node)}</Heading>
      <AttributesSection companyNodeWithEdges={companyNodeWithEdges} fullNodeWithEdges={fullNodeWithEdges} />
      <EdgesSection
        companyNodeWithEdges={companyNodeWithEdges}
        fullNodeWithEdges={fullNodeWithEdges}
        toggleEdgeAndNodeVisibility={toggleEdgeAndNodeVisibility}
      />
    </>
  );
}

function addEdgeAndNodeToCompany(nodeId: number, edgeId: number, companyId: number) {
  return Promise.all([
    ProductDatabaseConnection.createEdgeAssociation(edgeId, companyId),
    ProductDatabaseConnection.createNodeAssociation(nodeId, companyId),
  ]);
}
function removeEdgeAndNodeFromCompany(nodeId: number, edgeId: number, companyId: number) {
  return Promise.all([
    ProductDatabaseConnection.deleteEdgeAssociation(edgeId, companyId),
    ProductDatabaseConnection.deleteNodeAssociation(nodeId, companyId),
  ]);
}

export default function CompanyNodeItem({
  node,
  apiKey,
  companyId,
  toggleNodeVisibility,
  isVisible,
}: {
  node: Node;
  apiKey: string;
  companyId: number;
  toggleNodeVisibility: (nodeId: number, isVisible: boolean) => void;
  isVisible: boolean;
}) {
  const { loadingState, setIsLoading, setIsLoaded, setLoadingError } = useLoadingState(false);
  const [companyNodeWithEdges, setCompanyNodeWithEdges] = useState<NodeWithEdges | null>();
  const [fullNodeWithEdges, setFullNodeWithEdges] = useState<NodeWithEdges | null>();

  function loadNodesWithEdges() {
    setIsLoading();
    const loadingPromises = [
      ProductDatabaseConnection.getNode(node.id.toString(), apiKey).then((node) => setCompanyNodeWithEdges(node)),
      // Just assume that the global key is the master key for now
      ProductDatabaseConnection.getNode(node.id.toString()).then((node) => setFullNodeWithEdges(node)),
    ];
    Promise.all(loadingPromises).then(setIsLoaded).catch(setLoadingError);
  }

  function toggleEdgeAndNodeVisibility(nodeId: number, edgeId: number, isVisible: boolean) {
    setIsLoading();

    const promise: Promise<unknown> = isVisible
      ? removeEdgeAndNodeFromCompany(nodeId, edgeId, companyId)
      : addEdgeAndNodeToCompany(nodeId, edgeId, companyId);

    promise.then(loadNodesWithEdges).catch(setLoadingError);
  }

  if (loadingState.isError) return <>{loadingState.errorMessage}</>;

  if (!isVisible)
    return (
      <ListItem image={{ url: getStringValue("thumbnail", node.attributes) }}>
        <Heading type="h3">{getTitle(node)} </Heading>
        <ListItemActions>
          <Checkbox onClick={() => toggleNodeVisibility(node.id, isVisible)} checked={isVisible} label="Visible" />
        </ListItemActions>
      </ListItem>
    );

  const content =
    loadingState.isLoading || !companyNodeWithEdges || !fullNodeWithEdges ? (
      <Loading />
    ) : (
      <CompanyNodeContent
        fullNodeWithEdges={fullNodeWithEdges}
        companyNodeWithEdges={companyNodeWithEdges}
        toggleEdgeAndNodeVisibility={toggleEdgeAndNodeVisibility}
      />
    );

  return (
    <ListItem image={{ url: getStringValue("thumbnail", node.attributes) }}>
      <Expandable
        onActiveChanged={() => {
          if (!loadingState.isLoading && (!companyNodeWithEdges || !fullNodeWithEdges)) loadNodesWithEdges();
        }}
        collapsedContent={<Heading type="h3">{getTitle(node)} </Heading>}
        content={content}
      />
      <ListItemActions>
        <Checkbox onClick={() => toggleNodeVisibility(node.id, isVisible)} checked={isVisible} label="Visible" />
      </ListItemActions>
    </ListItem>
  );
}
