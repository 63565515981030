import { useState } from "react";
import { usePaginatedAPIQuery } from "./paginatedAPIQuery";

/**
 * We have two searchable paginated lists.
 * We are going to assume they contain objects with unique `id`s.
 * Thid id is strictly increasing (w.r.t the list order).
 *
 * Both lists represent the same underlying resources, but one of the lists is "incomplete",
 * i.e. `fullList` ⊇ `partialList`. Strictness is irrelevant.
 *
 * We want a way to output all items of the "complete" list, with an anotation describing if
 * it exists in the other list.
 *
 * This hook accomplishes exactly that, while adhering to the `usePaginatedAPIQuery`
 * interface and behaviour as closely as possible.
 *
 * It is implemented by fetching from the partial list to keep the highest seen partial id
 * in line (g.t.e) with the highest seen full list id.
 * Items are then annotated using the found partial list item ids.
 */
export default function usePaginatedAPIQueryWithAnnotatedVisibleItems<T>(
  fullListLoader: (page: number, pageSize: number, query: string) => Promise<T[]>,
  partialListLoader: (page: number, pageSize: number, query: string) => Promise<T[]>,
  /** Get a identification value for `T` such that they may be compared.
   * This is supposed to be strictly increasing in the underlying list resource order */
  getId: (a: T) => number,
  initialPage = 1,
  initialPageSize = 25,
  initialQuery = "",
  partialListPageSize = 50
) {
  const [seenPartialIds, setSeenPartialIds] = useState<number[]>([]);
  const [partialListPage, setPartialListPage] = useState(1);
  const [isPartialListExhausted, setIsPartialListExhausted] = useState(false);

  const resetPartialListKnowledge = () => {
    setSeenPartialIds([]);
    setPartialListPage(1);
    setIsPartialListExhausted(false);
  };

  async function loader(page: number, pageSize: number, query: string) {
    const fullListItems = await fullListLoader(page, pageSize, query);

    // We've exhausted the full list
    if (!fullListItems.length) return [];

    // Because of strictly increasing (and not empty), this is always the last item
    const highestSeenFullListId = getId(fullListItems[fullListItems.length - 1]);
    let highestSeenPartialListId = seenPartialIds[seenPartialIds.length - 1] ?? -1;

    let localPartialListPage = partialListPage;
    let localSeenPartialIds = [...seenPartialIds];

    while (!isPartialListExhausted && highestSeenPartialListId <= highestSeenFullListId) {
      const partialListItems = await partialListLoader(localPartialListPage++, partialListPageSize, query);

      localSeenPartialIds = [...localSeenPartialIds, ...partialListItems.map(getId)];
      if (partialListItems.length !== partialListPageSize) {
        setIsPartialListExhausted(true);
        break;
      }
      highestSeenPartialListId = localSeenPartialIds[localSeenPartialIds.length - 1];
    }

    setPartialListPage(localPartialListPage);
    setSeenPartialIds(localSeenPartialIds);

    return fullListItems.map((x) => ({
      ...x,
      visible: localSeenPartialIds.includes(getId(x)),
    }));
  }

  const hook = usePaginatedAPIQuery(loader, initialPage, initialPageSize, initialQuery);

  // The underlying resource has most likely changed
  // We can no longer assume that retrieved partial ids are correct
  const oldRefresh = hook.refresh;
  hook.refresh = () => {
    resetPartialListKnowledge();
    oldRefresh();
  };

  // Query has changed and there may be partial ids
  // formerly not found, that are now available in
  // pages already queried. We therefore reset.
  const oldSetQuery = hook.query.setQuery;
  hook.query.setQuery = (x) => {
    resetPartialListKnowledge();
    oldSetQuery(x);
  };

  return hook;
}
