import React from "react";
import { Link, useLocation } from "react-router-dom";
import wirevision_logo from "../../images/wirevision_logo_new.svg";
import { User } from "../../interfaces";
import { useStyles } from "../core/useStyles";
import styles from "./Header.module.css";

interface SiteHeaderProps {
  setUser?: (value: User | null) => void;
}

const SiteHeader = ({ setUser }: SiteHeaderProps): JSX.Element => {
  const { pathname } = useLocation();

  const cx = useStyles(styles);

  const headerLinks = [
    {
      url: "/products",
      name: "Products",
    },
    {
      url: "/companies",
      name: "Companies",
    },
    {
      url: "/settings",
      name: "Settings",
    },
  ];

  return (
    <header className={styles.siteHeader}>
      <div className={styles.inner}>
        <Link to="/" className={styles.headerLogo}>
          <img src={wirevision_logo} alt="wow" />
        </Link>
        <div className={styles.left}>
          {headerLinks.map((link, index) => (
            <Link
              key={index}
              to={link.url}
              className={cx({
                headerLink: [{ active: pathname.startsWith(link.url) }],
              })}
            >
              {link.name}
            </Link>
          ))}
        </div>
        <div className={styles.right}>
          <Link
            className={cx({
              headerLink: [],
            })}
            to="#"
            onClick={(e) => {
              e.preventDefault();
              if (setUser) setUser(null);
            }}
          >
            Sign out
          </Link>
          {/* <EnterKeyField /> */}
        </div>
      </div>
    </header>
  );
};

export default SiteHeader;
