import React, { FormEvent, useEffect, useState } from "react";
import { Button } from "../components/button/Button";
import { ButtonGroup } from "../components/button/ButtonGroup";
import Field from "../components/field/Field";
import Input from "../components/input/Input";
import Label from "../components/label/Label";
import List from "../components/list/List";
import ListItem from "../components/list/ListItem";
import ListItemActions from "../components/list/ListItemActions";
import Loading from "../components/loading/Loading";
import ModalPage, { GenericModalProps } from "../components/modal/ModalPage";
import useDelayedFunction from "../hooks/DelayedHook";
import { Node, NodeWithEdges, ToOrFromEdge } from "../interfaces";
import { emitCreatedEdgeEvent } from "../modules/EditProduct";
import { ProductDatabaseConnection } from "./ProductDatabaseConnection";
import SearchField from "./SearchField";
import Select from "../components/select/Select";

interface CreateEdgeProps extends GenericModalProps {
  id: number;
  direction: "from" | "to";
}

const CreateEdge = ({ id, routeProps, direction }: CreateEdgeProps): JSX.Element => {
  const [product, setProduct] = useState<NodeWithEdges>();
  const [searchValue, setSearchValue] = useState("");
  const [queriedProducts, setQueriedProducts] = useState<Node[]>([]);
  const [loading, setLoading] = useState(false);
  const [pickedProduct, setPickedProduct] = useState<Node>();
  const [savingProduct, setSavingProduct] = useState<boolean>(false);
  const [type, setType] = useState("");
  const [searchType, setSearchType] = useState<string>("node_id");

  const DisplayNode = ({ node }: { node: Node }) => {
    return (
      <div>
        <b>id:</b> {node.id} <b>manufacturer_article_number:</b>{" "}
        {node.attributes.manufacturer_article_number ?? "not found"} <b>type:</b> {node.attributes.type ?? "not found"}
      </div>
    );
  };

  const delayedSearch = useDelayedFunction<void>(search, 1000);

  const getRelatedNodeIds = () => [
    ...(product?.incoming_edges.map((x) => x.from.id) ?? []),
    ...(product?.outgoing_edges.map((x) => x.to.id) ?? []),
  ];

  const fetchProduct = (id: number) => {
    ProductDatabaseConnection.getProduct(id).then(setProduct).catch(console.warn);
  };

  useEffect(() => {
    fetchProduct(id);
  }, [id]);

  function search() {
    setLoading(true);
    if (searchType === "manufacturer_article_number") {
      ProductDatabaseConnection.getSimpleProductListByManufacturerArticleNumber(10, searchValue, 1).then((res) => {
        const relatedNodeIds = getRelatedNodeIds();
        const nodesNotAlreadyRelated = res.filter((queriedProduct) => !relatedNodeIds.includes(queriedProduct.id));
        setQueriedProducts(nodesNotAlreadyRelated);
        setLoading(false);
      });
    } else {
      ProductDatabaseConnection.getNode(searchValue.trim()).then((res) => {
        const node = res?.node;
        if (node) setQueriedProducts([node]);
        else setQueriedProducts([]);
        setLoading(false);
      });
    }
  }

  const handleSearch = (value: string) => {
    setSearchValue(value);
    delayedSearch();
  };

  const pickNode = (id: number) => {
    setPickedProduct(queriedProducts.find((q) => q.id === id));
    setSearchValue("");
    setQueriedProducts([]);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setSavingProduct(true);

    if (product && pickedProduct) {
      const from = direction === "from" ? product.node : pickedProduct;
      const to = direction === "to" ? product.node : pickedProduct;
      const edge = {
        attributes: {
          type: type,
        },
        from: from.id,
        to: to.id,
      };
      const createdEdge = await ProductDatabaseConnection.createEdge(edge);
      ProductDatabaseConnection.getProduct(product.node.id).then((node) => {
        const newEdge =
          node.incoming_edges.find((e) => e.id === createdEdge.id) ??
          node.outgoing_edges.find((e) => e.id === createdEdge.id);
        routeProps.history.goBack();
        emitCreatedEdgeEvent(newEdge as ToOrFromEdge);
        setSavingProduct(false);
      });
    }
  };

  return (
    <ModalPage title={`Adding edge ${direction} product ${product ? product.node.id : ""}`} routeProps={routeProps}>
      <form onSubmit={handleSubmit}>
        {product ? (
          <>
            <SearchField value={searchValue} onSearch={search} onValueChanged={handleSearch} placeholder="Search..." />
            <Select
              label="Search using: "
              options={[
                { value: "node_id", label: "Node id" },
                { value: "manufacturer_article_number", label: "Manufacturer Article Number" },
              ]}
              callback={(option) => setSearchType(option.value)}
            ></Select>
            {pickedProduct && (
              <>
                <List _style="alternating">
                  <ListItem>
                    <>
                      Connected to: <DisplayNode node={pickedProduct} />
                    </>
                    <ListItemActions>
                      <ButtonGroup align="right">
                        <Button
                          icon="close"
                          onClick={() => setPickedProduct(undefined)}
                          size="small"
                          variant="secondary"
                        />
                      </ButtonGroup>
                    </ListItemActions>
                  </ListItem>
                </List>
                <Field>
                  <Label htmlFor="type">Type</Label>
                  <Input name="type" id="type" value={type} onChange={({ target: { value } }) => setType(value)} />
                </Field>
              </>
            )}

            <List _style="alternating">
              {loading ? (
                <Loading />
              ) : (
                queriedProducts.map((queriedProduct, index) => (
                  <ListItem key={index}>
                    <DisplayNode node={queriedProduct} />
                    <ListItemActions>
                      <ButtonGroup align="right">
                        <Button variant="ghost" onClick={() => pickNode(queriedProduct.id)} size="small">
                          Select
                        </Button>
                      </ButtonGroup>
                    </ListItemActions>
                  </ListItem>
                ))
              )}
            </List>
            <ButtonGroup align="right">
              <Button variant="positive" type="submit" disabled={!(pickedProduct && type.length > 0) || savingProduct}>
                Save
              </Button>
            </ButtonGroup>
          </>
        ) : (
          <Loading />
        )}
      </form>
    </ModalPage>
  );
};

export default CreateEdge;
