import { useState } from "react";

export type LoadingState =
  | { isLoading: true; isError: false }
  | { isLoading: false; isError: false }
  | { isError: true; errorMessage: string };

export function useLoadingState(initialLoadingState = true) {
  const [loadingState, setLoadingState] = useState<LoadingState>({ isLoading: initialLoadingState, isError: false });

  const setIsLoading = () => setLoadingState({ isLoading: true, isError: false });
  const setIsLoaded = () => setLoadingState({ isLoading: false, isError: false });
  const setLoadingError = (errorMessage: unknown) =>
    setLoadingState({ isError: true, errorMessage: "" + errorMessage });

  return { loadingState, setIsLoaded, setIsLoading, setLoadingError, setLoadingState };
}
