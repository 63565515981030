import { ButtonBack, ButtonNext, CarouselProvider, DotGroup, Slide, Slider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import React from "react";
import { Icon } from "../components/icon/Icon";
import Item from "../components/item/Item";
import List from "../components/list/List";
import ListItem from "../components/list/ListItem";
import Loading from "../components/loading/Loading";
import { Table, TableCell, TableRow } from "../components/table/Table";
import { Attributes, HTTPResult, JSONValue, NodeWithEdges, FromEdge, SDMTake } from "../interfaces";
import { filterAttributes, getStringValue } from "../lib/node";
import { AttributeRow } from "./attributes/AttributeRow";
import FramedContent from "./FramedContent";
import { ProductDatabaseConnection } from "./ProductDatabaseConnection";
import SDMClient from "./SDMClient";

interface propInterface {
  id: number;
}

interface stateInterface {
  product: NodeWithEdges;
  videosResult: HTTPResult<SDMTake[]> | null;
  loading: boolean;
}

export default class ProductInspectCard extends React.Component<propInterface, stateInterface> {
  constructor(props: propInterface) {
    super(props);

    this.state = {
      product: {
        node: {
          id: this.props.id,
          attributes: {},
        },
        incoming_edges: [],
        outgoing_edges: [],
      },
      videosResult: null,
      loading: false,
    };
  }

  componentDidMount(): void {
    this.fetchProduct = this.fetchProduct.bind(this);
    this.fetchProduct();
  }

  fetchProduct(): void {
    this.setState({ loading: true });

    ProductDatabaseConnection.getProduct(this.state.product.node.id)
      .then((node) => {
        this.setState({
          loading: false,
          product: node,
        });
      })
      .catch(console.warn);

    SDMClient.getTakesForNodeID(this.state.product.node.id)
      .then((videosResult) => this.setState({ videosResult }))
      .catch(console.warn);
  }

  renderImages(imagesAttribute: JSONValue): JSX.Element | undefined {
    if (typeof imagesAttribute === "string") imagesAttribute = [imagesAttribute];
    if (!Array.isArray(imagesAttribute)) return;

    const images = imagesAttribute.map((image, i) => (
      <Slide key={i} index={i}>
        <div className="carouselImage">
          <img src={image?.toString()} alt="" className="" />
        </div>
      </Slide>
    ));

    return (
      <div className="ui very padded segment carouselWrapper">
        <CarouselProvider naturalSlideWidth={900} naturalSlideHeight={320} totalSlides={images.length}>
          <Slider>{images}</Slider>
          <div className="carouselNavigation">
            <ButtonBack>
              <Icon name="chevron_left" size="m" />
            </ButtonBack>
            <ButtonNext>
              <Icon name="chevron_right" size="m" />
            </ButtonNext>
          </div>
          <DotGroup />
        </CarouselProvider>
      </div>
    );
  }

  renderFiles(fileEdges: FromEdge[]): JSX.Element {
    const items = fileEdges.map((edge, index) => {
      const fileAttributes = edge.from.attributes;
      return (
        <ListItem key={index}>
          <a href={getStringValue("href", fileAttributes)} title={getStringValue("description", fileAttributes)}>
            {fileAttributes["title"]}
          </a>
        </ListItem>
      );
    });

    return (
      <TableRow>
        <TableCell>files</TableCell>
        <TableCell>
          <List _style="plain">{items}</List>
        </TableCell>
      </TableRow>
    );
  }

  renderFramedContent(framedContentEdges: FromEdge[]): JSX.Element[] {
    return framedContentEdges.map((edge) => <FramedContent {...edge} />);
  }

  renderVideos(videosResult: HTTPResult<SDMTake[]> | null): JSX.Element {
    let content;

    if (videosResult === null) {
      content = <>Loading videos...</>;
    } else if (videosResult._kind !== "success") {
      let errorMessage;
      switch (videosResult.code) {
        case 403:
          errorMessage = "You do not have permission to view videos";
          break;
        case 404:
          errorMessage = "No videos have been recorded";
          break;
        default:
          errorMessage = "Something went wrong";
      }

      content = <>{errorMessage}</>;
    } else {
      const filesDescriptions = videosResult.result.flatMap((take) =>
        take.files.map(
          (f) => `${f.data.container === "bucket" ? "commited" : "staged"} ${take.data.type}/${take.name}/${f.name}`
        )
      );
      const listItems = filesDescriptions
        .sort((a, b) => a.localeCompare(b))
        .map((fileDescription, i) => <ListItem key={i}>{fileDescription}</ListItem>);

      content = <List _style="plain">{listItems}</List>;
    }
    return (
      <TableRow>
        <TableCell>Videos</TableCell>
        <TableCell>{content}</TableCell>
      </TableRow>
    );
  }

  renderProperties(properties: Attributes): JSX.Element {
    const exemptedItems = ["images", "thumbnail", "article_number", "files", "framed_images", "instruction_media"];
    const filteredAttributes = filterAttributes(properties, (attributeKey) => !exemptedItems.includes(attributeKey));

    return (
      <>
        {Object.entries(filteredAttributes).map(([key, value], index) => (
          <AttributeRow key={index} attribute={{ key, value }} />
        ))}
      </>
    );
  }

  render(): JSX.Element {
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <Item>
        {this.renderImages(this.state.product.node.attributes["images"])}
        {this.renderFramedContent(
          this.state.product.incoming_edges.filter(
            (edge) =>
              edge.from.attributes.type === "framed_content" || edge.from.attributes.type === "instruction_media"
          )
        )}
        <Table variant="bordered">
          {this.renderProperties(this.state.product.node.attributes)}
          {this.renderFiles(this.state.product.incoming_edges.filter((edge) => edge.from.attributes.type === "file"))}
          {this.renderVideos(this.state.videosResult)}
        </Table>
      </Item>
    );
  }
}
