import React from "react";
import { useRouteMatch } from "react-router-dom";
import BasicLink from "../components/link/BasicLink";
import List from "../components/list/List";
import ListItem from "../components/list/ListItem";
import { PageContainer } from "../components/page/PageContainer";
import PageHeader from "../components/page/PageHeader";
import Heading from "../components/typography/Heading";
import { Company } from "../interfaces";

export default function CompanyList({ companies }: { companies: Company[] }) {
  const match = useRouteMatch();

  return (
    <PageContainer header={<PageHeader left={<Heading type="h1">Companies</Heading>} />}>
      <List _style="bordered">
        {companies.map((company) => (
          <ListItem key={company.id}>
            <BasicLink
              to={{
                pathname: `${match.url}/${company.id}`,
              }}
            >
              <Heading type="h3">{company.name}</Heading>
            </BasicLink>
          </ListItem>
        ))}
      </List>
    </PageContainer>
  );
}
