import React, { useState } from "react";
import { useStyles } from "../core/useStyles";
import { Icon } from "../icon/Icon";
import styles from "./Expandable.module.css";

interface ExpandableProps {
  content: React.ReactNode;
  initActive?: boolean;
  collapsedContent?: React.ReactNode;
  onActiveChanged?: (newStatus: boolean) => void;
}

const Expandable = ({
  content,
  collapsedContent,
  initActive = false,
  onActiveChanged,
}: ExpandableProps): JSX.Element => {
  const [active, setActive] = useState<boolean>(initActive);

  const cx = useStyles(styles);

  const toggle = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target instanceof HTMLInputElement) {
      return;
    }

    if (onActiveChanged) onActiveChanged(!active);
    setActive(!active);
  };

  return (
    <div
      className={cx({
        expandable: [{ active }],
      })}
    >
      <div className={styles.collapsedContent} onClick={toggle}>
        <span className={styles.expandButton}>
          <Icon name={`expand_${active ? "less" : "more"}`} />
        </span>
        {collapsedContent && !active && <div>{collapsedContent}</div>}
      </div>
      <div
        className={cx({
          content: [],
        })}
      >
        {content}
      </div>
    </div>
  );
};

export default Expandable;
