import { KBarProvider } from "kbar";
import React, { useState } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import "./components/core/variables.css";
import SiteHeader from "./components/header/SiteHeader";
import { Icon } from "./components/icon/Icon";
import SiteContainer from "./components/site/SiteContainer";
import { getSearchKeys } from "./lib/search_keys";
import { tokenIsValid, useUser } from "./lib/useUser";
import KBarPortalModal from "./modules/KBarPortal";
import Login from "./pages/Login";
import NotFound from "./modules/NotFound";
import ProductRouter from "./modules/ProductRouter";
import Settings from "./pages/Settings";
import CompanyRouter from "./modules/CompanyRouter";
// import "./semantic/semantic.css";

function App(): JSX.Element {
  const { user, setUser } = useUser();
  const [searchKey, setSearchKey] = useState("manufacturer_article_number");

  const renderProtected = () => {
    if (!user || !tokenIsValid(user.token)) {
      return (
        <Switch>
          <SiteContainer centerBoth={true} noBackground={true}>
            <Route
              path="/"
              render={(props) => <Login routeProps={props} setUser={(value) => setUser(value)} />}
            ></Route>
          </SiteContainer>
        </Switch>
      );
    } else {
      return (
        <>
          <SiteHeader setUser={(value) => setUser(value)} />
          <SiteContainer>
            <Switch>
              <Route path="/products" component={ProductRouter} />
              <Route path="/companies">
                <CompanyRouter />
              </Route>
              <Route path="/settings">
                <Settings />
              </Route>
              <Route path="/error">
                <NotFound />
              </Route>
              <Route path="/" render={() => <Redirect to="/products" />}></Route>
            </Switch>
          </SiteContainer>
        </>
      );
    }
  };

  const keys = getSearchKeys();

  return (
    <KBarProvider
      actions={[
        {
          id: "search",
          name: "Search",
          keywords: "search product node",
          icon: <Icon name="search" />,
        },
        {
          id: "edit",
          name: "Edit",
          shortcut: ["e"],
          keywords: "edit product node",
          icon: <Icon name="edit" />,
        },
        {
          id: "settings",
          name: "Settings",
          shortcut: ["s"],
          keywords: "settings preferences",
          perform: () => (window.location.pathname = "/settings"),
          icon: <Icon name="settings" />,
        },
        {
          id: "create",
          name: "Create product",
          shortcut: ["c"],
          keywords: "create node product new",
          perform: () => (window.location.pathname = "/products/edit"),
          icon: <Icon name="add" />,
        },
        {
          id: "keychange",
          name: "Change search key",
          icon: <Icon name="key" />,
        },
        ...keys.map((key) => ({
          id: `key_${key}`,
          name: key,
          parent: "keychange",
          keywords: key,
          perform: () => setSearchKey(key),
        })),
      ]}
    >
      <KBarPortalModal searchKey={searchKey} />
      <Router>{renderProtected()}</Router>
    </KBarProvider>
  );
}

export default App;
