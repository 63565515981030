import React from "react";
import { Button } from "../components/button/Button";
import Expandable from "../components/expandable/Expandable";
import Field from "../components/field/Field";
import FileUpload from "../components/fileupload/FileUpload";
import { Icon } from "../components/icon/Icon";
import AutocompleteInput from "../components/input/AutocompleteInput";
import Input from "../components/input/Input";
import Label from "../components/label/Label";
import { dynamicOnChangeType, JSONValue } from "../interfaces";
import { getAutocompleteKeys } from "../lib/autocomplete";
import { attributeValueToString } from "../lib/node";

interface AttributeContainerProps {
  attributeKey: string;
  attributeValue: JSONValue;
  attributeIndex: number;
  handleChange: dynamicOnChangeType;
  nodeId: string;
  removeInput: (attributeIndex: number, inputIndex: number) => void;
  addInput: (index: number, value?: string) => void;
}

const AttributeContainer = (props: AttributeContainerProps): JSX.Element => {
  const renderValueInput = (inputValue: string, inputIndex: number, withDelete: boolean) => {
    return (
      <>
        <Input
          name="value"
          value={inputValue}
          placeholder={"Value"}
          onChange={({ target: { name, value } }) => props.handleChange(props.attributeIndex, inputIndex, name, value)}
          action={
            withDelete && {
              icon: "trash",
              color: "red",
              onAction: (_) => props.removeInput(attributeIndex, inputIndex),
            }
          }
        />
      </>
    );
  };

  const values = (Array.isArray(props.attributeValue) ? props.attributeValue : [props.attributeValue]).map(
    attributeValueToString
  );

  const attributeIndex = props.attributeIndex;

  return (
    <Field>
      <Expandable
        initActive={props.attributeKey === "" || values[0] === ""}
        collapsedContent={
          <Field inline={true}>
            <Label htmlFor="">{props.attributeKey}</Label>
            {values.length === 1 ? renderValueInput(values[0], 0, false) : <Icon name="more_horiz" />}
          </Field>
        }
        content={
          <>
            <Field>
              <Label htmlFor="key">Key</Label>
              <AutocompleteInput
                name="key"
                value={props.attributeKey || ""}
                placeholder={"Key"}
                onChange={(name, value) => props.handleChange(attributeIndex, 0, name, value)}
                suggestions={getAutocompleteKeys()}
              />
            </Field>
            <Field>
              <Label htmlFor="">{values.length > 1 ? "Values" : "Value"}</Label>
              {values.map((inputValue, inputIndex) => {
                const suffix = inputValue.split(".").pop() ?? "";
                const isImageUrl = ["jpeg", "jpg", "webp", "gif", "png"].includes(suffix);
                return (
                  <Field key={inputIndex.toString()} columns={isImageUrl ? 2 : 1}>
                    {isImageUrl && (
                      <a href={inputValue} target="_blank" rel="noopener noreferrer">
                        <img src={inputValue} height={100} style={{ borderRadius: 4 }} alt="" />
                      </a>
                    )}
                    <Input
                      fluid={false}
                      name="value"
                      value={inputValue || ""}
                      placeholder={"Value"}
                      onChange={({ target: { name, value } }) =>
                        props.handleChange(attributeIndex, inputIndex, name, value)
                      }
                      action={{
                        icon: "delete",
                        color: "red",
                        onAction: (_) => props.removeInput(attributeIndex, inputIndex),
                      }}
                    />
                  </Field>
                );
              })}
            </Field>
            <FileUpload
              nodeId={props.nodeId}
              uploadCallback={(uris) => uris.forEach((uri) => props.addInput(attributeIndex, uri))}
              helpText="Will upload images and add fields. Fields will be commited to node upon page save"
            />
            <Field>
              <Button type="button" onClick={(_) => props.addInput(attributeIndex)} variant="positive" icon="add">
                Add value
              </Button>
            </Field>
          </>
        }
      />
    </Field>
  );
};

export default AttributeContainer;
