import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Button } from "../components/button/Button";
import { ButtonGroup } from "../components/button/ButtonGroup";
import Checkbox from "../components/checkbox/Checkbox";
import Input from "../components/input/Input";
import BasicLink from "../components/link/BasicLink";
import List from "../components/list/List";
import ListItem from "../components/list/ListItem";
import ListItemActions from "../components/list/ListItemActions";
import Loading from "../components/loading/Loading";
import { PageContainer } from "../components/page/PageContainer";
import PageHeader from "../components/page/PageHeader";
import Heading from "../components/typography/Heading";
import { Node } from "../interfaces";
import { getStringValue } from "../lib/node";
import Setting from "../lib/setting";
import { getGlobalKey, setGlobalKeyListeners } from "../StateManager";
import { ProductDatabaseConnection } from "./ProductDatabaseConnection";
import SearchField from "./SearchField";

interface stateInterface {
  items: Node[];
  productOpened: string;
  queryString: string;
  loadProductID: string;
  isLoading: boolean;
  page: number;
  deleteModalVisible: boolean;
  showFilters: boolean;
  artNumberOnly: boolean;
}

interface propInterface {
  routeProps: RouteComponentProps;
}

const maxFetchLimit = 50;

export default class ProductList extends React.Component<propInterface, stateInterface> {
  searchTimer?: number;

  constructor(props: propInterface) {
    super(props);

    const setting = new Setting("article_number_key");

    this.state = {
      items: [],
      productOpened: "",
      queryString: "",
      loadProductID: "",
      isLoading: true,
      page: 0,
      deleteModalVisible: false,
      showFilters: setting.getStoredValue() !== setting.defaultValue,
      artNumberOnly: true,
    };

    this.getProductList = this.getProductList.bind(this);
    this.shiftPage = this.shiftPage.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleFilters = this.toggleFilters.bind(this);
    this.toggleArticleNumbers = this.toggleArticleNumbers.bind(this);
    this.onSearchUpdate = this.onSearchUpdate.bind(this);
    setGlobalKeyListeners([this.getProductList]);
  }

  getProductList(): void {
    this.setState({
      items: [],
      productOpened: "",
      isLoading: true,
    });

    ProductDatabaseConnection.getSimpleProductListByManufacturerArticleNumber(
      maxFetchLimit,
      this.state.queryString,
      this.state.page,
      this.state.artNumberOnly
    )
      .then((simpleProduct) => {
        this.setState({
          items: simpleProduct ?? [],
          isLoading: false,
        });
      })
      .catch((reason) => {
        console.warn(reason);
      });
  }

  toggleModal(): void {
    this.setState((prevState) => ({
      deleteModalVisible: !prevState.deleteModalVisible,
    }));
  }

  getPageFromQuery(): number {
    const page = new URLSearchParams(this.props.routeProps.location.search).get("page") ?? "1";
    return parseInt(page);
  }

  setTitle(): void {
    document.title = `Products - Page ${this.state.page} | PDB Navigator`;
  }

  componentDidUpdate(prevProps: propInterface, prevState: stateInterface): void {
    const page = this.getPageFromQuery();

    if (prevState.artNumberOnly !== this.state.artNumberOnly) {
      this.getProductList();
    }

    if (prevState.page !== page) {
      this.setState({ page: page }, this.getProductList);
      this.setTitle();
    }
  }

  componentDidMount(): void {
    const page = this.getPageFromQuery();
    this.setState({ page: page }, this.getProductList);
    this.setTitle();
  }

  shiftPage(by: number): void {
    // NOTE second page for wierd reason returns maxlim - 1 items
    if (this.state.page + by < 1 || (this.state.items.length < maxFetchLimit - 1 && by > 0)) {
      return;
    }

    const page = Math.max(1, this.state.page + by);

    this.props.routeProps.history.push(`?page=${page}`);

    this.setState({ page: page }, this.getProductList);
  }

  toggleFilters(): void {
    this.setState((prevState) => ({
      showFilters: !prevState.showFilters,
    }));
  }

  toggleArticleNumbers(): void {
    this.setState((prevState) => ({
      artNumberOnly: !prevState.artNumberOnly,
    }));
  }

  onSearchUpdate(newQueryString: string) {
    // Delay if user is typing one char at a time
    const delay = newQueryString.includes(this.state.queryString) ? 1000 : 0;
    this.setState({ queryString: newQueryString }, () => {
      window.clearTimeout(this.searchTimer);
      this.searchTimer = window.setTimeout(() => {
        this.setState({ page: 0 }, this.getProductList);
      }, delay);
    });
  }

  render(): JSX.Element {
    let content;

    if (this.state.isLoading) {
      content = <Loading />;
    } else if (!this.state.items.length) {
      const message =
        getGlobalKey().key === "" ? (
          <p>Please enter a valid wirevision api-key and then reload the product list.</p>
        ) : (
          <p>No products found.</p>
        );

      content = <div className="ui warning message appMessage">{message}</div>;
    } else {
      const articleNumberSetting = new Setting("article_number_key");
      content = this.state.items.map((element, index) => {
        return (
          <ListItem key={index} image={{ url: getStringValue("thumbnail", element.attributes) }}>
            <BasicLink
              to={{
                pathname: `/products/show/${element.id}`,
                search: this.props.routeProps.location.search,
                state: { background: this.props.routeProps.location },
              }}
            >
              <Heading type="h3">
                {element.attributes.alias ?? element.attributes[articleNumberSetting.getStoredValue()]}
              </Heading>
            </BasicLink>
            <ListItemActions>
              <ButtonGroup align="right">
                <Link
                  to={{
                    pathname: `/products/show/${element.id}`,
                    search: this.props.routeProps.location.search,
                    state: { background: this.props.routeProps.location },
                  }}
                >
                  <Button variant="secondary" icon="visibility" size="small">
                    Show
                  </Button>
                </Link>
                <Link to={`/products/edit/${element.id}`}>
                  <Button variant="secondary" icon="edit" size="small">
                    Edit
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: `/products/delete/${element.id}`,
                    search: this.props.routeProps.location.search,
                    state: { background: this.props.routeProps.location },
                  }}
                >
                  <Button variant="secondary" icon="delete" size="small">
                    Delete
                  </Button>
                </Link>
              </ButtonGroup>
            </ListItemActions>
          </ListItem>
        );
      });
    }

    return (
      <PageContainer
        header={
          <PageHeader
            left={
              <>
                <SearchField
                  value={this.state.queryString}
                  onSearch={this.getProductList}
                  onValueChanged={this.onSearchUpdate}
                  placeholder="Search for product"
                />
                <Input
                  name=""
                  placeholder="Load by ID"
                  onChange={({ target: { value } }) => {
                    this.setState({
                      loadProductID: value,
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      this.props.routeProps.history.push(`/products/edit/${this.state.loadProductID}`);
                    }
                  }}
                  action={{
                    color: "gray",
                    icon: "sync",
                    onAction: () => {
                      if (this.state.loadProductID.length)
                        this.props.routeProps.history.push(`/products/edit/${this.state.loadProductID}`);
                    },
                  }}
                  value={this.state.loadProductID}
                />
                <Button icon="filter_list" variant="secondary" type="button" onClick={this.toggleFilters} />
              </>
            }
            right={
              <>
                <Link to="/products/edit">
                  <Button variant="primary" icon="add">
                    Create product
                  </Button>
                </Link>
                <ButtonGroup align="right">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      this.shiftPage(-1);
                    }}
                    icon="chevron_left"
                  ></Button>
                  Page: {this.state.page}
                  <Button
                    variant="secondary"
                    onClick={() => {
                      this.shiftPage(1);
                    }}
                    icon="chevron_right"
                  ></Button>
                </ButtonGroup>
              </>
            }
          />
        }
        subheader={
          this.state.showFilters && (
            <PageHeader
              left={
                <Checkbox
                  checked={this.state.artNumberOnly}
                  label="Has article number"
                  onClick={this.toggleArticleNumbers}
                />
              }
            />
          )
        }
      >
        <List _style="bordered">{content}</List>

        <ButtonGroup align="right">
          <Button
            variant="secondary"
            onClick={() => {
              this.shiftPage(-1);
            }}
            icon="chevron_left"
          ></Button>
          Page: {this.state.page}
          <Button
            variant="secondary"
            onClick={() => {
              this.shiftPage(1);
            }}
            icon="chevron_right"
          ></Button>
        </ButtonGroup>
      </PageContainer>
    );
  }
}
