import { Button } from "../button/Button";
import { ButtonGroup } from "../button/ButtonGroup";
import { useState, useCallback, useMemo } from "react";
import { BlockBlobClient, AnonymousCredential } from "@azure/storage-blob";
import { useUser } from "../../lib/useUser";

import { DropzoneRootProps, useDropzone } from "react-dropzone";
import React from "react";
import { authBaseURL, azureStaticAssetEndpoint } from "../../help";
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "100px",
  borderWidth: 2,
  borderRadius: 10,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function Dropzone({ onFilesAdded }: { onFilesAdded: (f: File[]) => void }) {
  const onDrop = useCallback(
    (files: File[]) => {
      onFilesAdded(files);
    },
    [onFilesAdded]
  );
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({ onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style } as DropzoneRootProps)}>
        <input {...getInputProps()} />
        <p>Drag and drop some files here, or click to select files</p>
      </div>
    </div>
  );
}

const FileUpload = ({
  nodeId,
  uploadCallback,
  helpText,
}: {
  nodeId: string;
  uploadCallback: (uris: string[]) => void;
  helpText?: string;
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  const { user } = useUser();

  const addFiles = (files: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const getSasToken = useCallback(async (): Promise<{ token: string }> => {
    const authUrl = new URL(`/api/sas-token-wt-static-upload`, authBaseURL).href;
    const fetchResponse = await fetch(authUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });
    const res = await fetchResponse.json().catch((e) => setError("unable to fetch sas token!"));
    return res;
  }, [user?.token]);

  const uploadFile = async (nodeId: string, file: File): Promise<string> => {
    const imageUri = new URL(`${azureStaticAssetEndpoint}${nodeId}_${Date.now()}_${file.name}`).href;

    const azureKey = await getSasToken();

    const url = `${imageUri}?${azureKey.token}`;

    const blockBlobClient = new BlockBlobClient(url, new AnonymousCredential());
    const res = await blockBlobClient.uploadData(file);
    const requestStatus = res._response.status;

    if (requestStatus !== 201) {
      setError("failed to upload image!");
      return "";
    }

    return imageUri;
  };

  const submitFiles = async () => {
    if (!files || files.length === 0) {
      setError("No files have been provided for submit!");
      return;
    }

    const imageUris = await Promise.all(files.map((f) => uploadFile(nodeId, f)));
    uploadCallback(imageUris);
    setFiles([]);
    setIsSubmitting(false);
    setVisible(false);
  };

  const RenderFileUploads = () => (
    <>
      {error && (
        <>
          <br />
          <p style={{ color: "red" }}>{error}</p>
          <br />
        </>
      )}

      <Dropzone onFilesAdded={(f: File[]) => addFiles(f)} />
      <br />
      {files.map((f, i) => (
        <div key={i}>{f.name}</div>
      ))}
      <br />
    </>
  );

  if (visible)
    return (
      <>
        <br />
        <h1>Upload static files</h1>
        <br />
        <p className="">{helpText}</p>
        <br />
        <RenderFileUploads />
        <br />
        <ButtonGroup align="right">
          <Button
            variant="secondary"
            onClick={() => {
              setFiles([]);
              setVisible(false);
            }}
          >
            Cancel upload
          </Button>
          <Button loading={isSubmitting} onClick={submitFiles}>
            Upload Images
          </Button>
        </ButtonGroup>
      </>
    );
  else
    return (
      <div style={{ marginBottom: "1rem" }}>
        <Button icon="upload_file" variant="secondary" onClick={() => setVisible(!visible)}>
          Upload files
        </Button>
      </div>
    );
};

export default FileUpload;
