import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Button } from "../components/button/Button";
import { ButtonGroup } from "../components/button/ButtonGroup";
import Item from "../components/item/Item";
import Loading from "../components/loading/Loading";
import ModalPage, { GenericModalProps } from "../components/modal/ModalPage";
import { NodeWithEdges } from "../interfaces";
import { ProductDatabaseConnection } from "./ProductDatabaseConnection";

interface DeleteProductProps extends GenericModalProps {
  id: number;
}

const DeleteProduct = ({ id, routeProps }: DeleteProductProps): JSX.Element => {
  const [product, setProduct] = useState<NodeWithEdges>();
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    ProductDatabaseConnection.getProduct(id).then(setProduct).catch(console.warn);
  }, [id]);

  const deleteProduct = () => {
    ProductDatabaseConnection.deleteProduct(id.toString())
      .then((response) => {
        if (response) {
          setDeleted(true);
        }
      })
      .catch((reason) => console.warn(reason));
  };

  if (deleted) {
    return <Redirect to="/" />;
  }

  return (
    <ModalPage routeProps={routeProps} title={`Deleting product ${product ? product.node.id : ""}`}>
      {product ? (
        <>
          <Item>You are about to delete the product: {product.node.id}</Item>
          <ButtonGroup align="right">
            <Button variant="negative" onClick={deleteProduct}>
              Delete
            </Button>
            <Button variant="secondary" onClick={() => routeProps.history.goBack()}>
              Cancel
            </Button>
          </ButtonGroup>
        </>
      ) : (
        <Loading />
      )}
    </ModalPage>
  );
};

export default DeleteProduct;
