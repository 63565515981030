import React from "react";
import { useStyles } from "../core/useStyles";
import styles from "./Field.module.css";

interface FieldProps {
  children: React.ReactNode;
  columns?: 1 | 2 | 3;
  inline?: boolean;
}

const Field = ({ children, columns = 1, inline = false }: FieldProps): JSX.Element => {
  const cx = useStyles(styles);
  return (
    <div
      className={cx({
        field: [{ grid: columns > 1 }, `grid_${columns}`, { inline }],
      })}
    >
      {children}
    </div>
  );
};

export default Field;
