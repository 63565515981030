export type SearchExpression = LogicalExpression | RelationalExpression;
export type RelationalExpression = [string, string, string];
export type LogicalExpression =
  | {
      AND: [SearchExpression, SearchExpression];
    }
  | {
      OR: [SearchExpression, SearchExpression];
    }
  | {
      NOT: [SearchExpression];
    };

export function isRelationalExpression(expression: unknown): expression is RelationalExpression {
  const allowedOperators = ["==", "!=", "LIKE", "ILIKE"];
  return (
    Array.isArray(expression) &&
    expression.length === 3 &&
    expression.every((x) => typeof x === "string" && x.length > 0) &&
    allowedOperators.includes(expression[1])
  );
}
export function isLogicalExpression(expression: unknown): expression is LogicalExpression {
  const areExpressions = (x: SearchExpression[]): boolean => x.every(isSearchExpression);

  if (typeof expression !== "object" || !expression) return false; // check non-null object

  const { AND, OR, NOT } = expression as { AND: unknown; OR: unknown; NOT: unknown };

  if (Number(!!AND) + Number(!!OR) + Number(!!NOT) !== 1) return false; // check one key exists

  if (AND) return Array.isArray(AND) && AND.length > 1 && areExpressions(AND); // check array of expressions and len > 1
  if (OR) return Array.isArray(OR) && OR.length > 1 && areExpressions(OR); // check array of expressions and len > 1
  if (NOT) return Array.isArray(NOT) && NOT.length === 1 && areExpressions(NOT); // check array of expressions and len == 1

  return false;
}

export function isSearchExpression(expression: unknown): expression is SearchExpression {
  return isRelationalExpression(expression) || isLogicalExpression(expression);
}

export function expressionToString(expression: SearchExpression): string {
  return JSON.stringify(expression);
}

export function parseStringToExpression(query: string): SearchExpression | null {
  try {
    const x = JSON.parse(query);
    if (isSearchExpression(x)) return x;
  } catch {
    // Just return null below, we couldn't parse the query string
  }

  return null;
}

/** A searchstring is valid if it is either empty (not searching) or a valid expression */
export function isValidSearchString(searchString: string) {
  return searchString === "" || parseStringToExpression(searchString);
}
