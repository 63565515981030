import React, { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Loading from "../components/loading/Loading";
import { useLoadingState } from "../hooks/LoadingHook";
import { Company } from "../interfaces";
import CompanyList from "./CompanyList";
import { ProductDatabaseConnection } from "./ProductDatabaseConnection";
import ViewCompany from "./ViewCompany";

export default function CompanyRouter() {
  const match = useRouteMatch();
  const [companies, setCompanies] = useState<Company[]>([]);
  const { loadingState, setIsLoading, setIsLoaded, setLoadingError } = useLoadingState();

  useEffect(() => {
    setIsLoading();
    ProductDatabaseConnection.getCompanies(100, 0).then(setCompanies).then(setIsLoaded).catch(setLoadingError);
  }, []);

  if (loadingState.isError) return <>{loadingState.errorMessage}</>;
  if (loadingState.isLoading) return <Loading />;

  return (
    <Switch>
      <Route
        path={`${match.url}/:id`}
        render={({ history, match }) => {
          const company = companies.find((c) => c.id === parseInt(match.params.id));
          if (company) return <ViewCompany company={company} />;
          else history.replace("/error");
        }}
      />
      <Route path={`${match.url}/`}>{<CompanyList companies={companies} />}</Route>
    </Switch>
  );
}
