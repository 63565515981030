import React, { useState } from "react";
import { useStyles } from "../core/useStyles";
import { Icon } from "../icon/Icon";
import styles from "./StyledExpandable.module.css";

interface ExpandableProps {
  title: React.ReactNode | string;
  content: React.ReactNode;
  initActive?: boolean;
  visibleWhenCollapsed?: React.ReactNode;
}

const StyledExpandable = ({
  title,
  content,
  visibleWhenCollapsed,
  initActive = false,
}: ExpandableProps): JSX.Element => {
  const [active, setActive] = useState<boolean>(initActive);

  const toggleExpandableState = () => {
    setActive((active) => !active);
  };

  const cx = useStyles(styles);

  return (
    <div
      className={cx({
        expandable: [{ active }],
      })}
    >
      <div className={styles.title}>
        <span className={styles.expandableOpener} onClick={toggleExpandableState}>
          <Icon name={`expand_${active ? "less" : "more"}`} />
          {title}
        </span>
        {visibleWhenCollapsed && !active && <div style={{ marginTop: "8px" }}>{visibleWhenCollapsed}</div>}
      </div>
      <div
        className={cx({
          content: [],
        })}
      >
        {content}
      </div>
    </div>
  );
};

export default StyledExpandable;
