import { pdbBaseURL } from "../help";
import {
  RequestParams,
  FilterType,
  CompanyNodeAssociation,
  Company,
  WhitelistedAttribute,
  ApiKey,
  NodeWithEdges,
  CompanyEdgeAssociation,
  Node,
  Edge,
  Attributes,
} from "../interfaces";
import Setting from "../lib/setting";
import { getGlobalKey } from "../StateManager";

interface Pagination {
  limit: number;
  offset: number;
}

const _getHeaders = (): Headers => {
  const token = JSON.parse(localStorage.getItem("user") ?? '{"token": ""}')["token"];

  return new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  });
};

const getCompanies = async (limit: number, offset: number) => {
  const url = "companies/?skip=" + offset + "&limit=" + limit;
  const res = await makeRequest({ url, method: "get" });
  return res as Company[];
};

const getApiKeys = async ({ offset, limit }: Pagination) => {
  const url = "api_key/?skip=" + offset + "&limit=" + limit;
  const res = await makeRequest({ url, method: "get" });
  return res as ApiKey[];
};

const getWhitelistedAttributes = async (companyId: number) => {
  const url = "whitelisted-attributes/?company_id=" + companyId;
  const res = await makeRequest({ url, method: "get" });
  return res as WhitelistedAttribute[];
};

const putWhitelistedAttributes = async (companyId: number, whitelistedAttributes: WhitelistedAttribute[]) => {
  const url = "whitelisted-attributes/?company_id=" + companyId;
  const res = await makeRequest({ url, method: "put", body: whitelistedAttributes.map((wa) => wa.attribute) });
  return res as WhitelistedAttribute[];
};

const getUniqueAttributeNames = (): Promise<string[]> => makeRequest({ url: "attributes/unique-names", method: "get" });

const getSimpleProductListByManufacturerArticleNumber = async (
  maxFetchLimit: number,
  searchString: string,
  page: number,
  onlyArticleNumber = true,
  searchKey = "manufacturer_article_number"
): Promise<Node[]> => {
  const keySetting = new Setting("article_number_key");

  let searchFilter: FilterType = {
    OR: [
      [keySetting.getStoredValue(), "ILIKE", `%${searchString}%`],
      [searchKey, "ILIKE", `%${searchString.trim()}%`],
    ],
  };

  if (onlyArticleNumber) {
    searchFilter = {
      AND: [searchFilter, [keySetting.getStoredValue(), "!=", ``]],
    };
  }

  const url =
    "nodes/?q=" +
    encodeURIComponent(JSON.stringify(searchFilter)) +
    "&skip=" +
    Math.max(0, maxFetchLimit * (page - 1)) +
    "&limit=" +
    maxFetchLimit;

  const res = await makeRequest({ url, method: "get" });

  return res as Node[];
};

const getNodes = async (q = "", { limit, offset }: Pagination, apiKey?: string): Promise<Node[]> => {
  if (!apiKey) apiKey = getGlobalKey().key;
  const qParam = q !== "" ? `&q=${encodeURIComponent(q)}` : ""; // "" implies we aren't searching/filtering
  const res = await fetch(`${pdbBaseURL}nodes/?api_key=${apiKey}&limit=${limit}&skip=${offset}` + qParam, {
    headers: _getHeaders(),
    method: "get",
  });
  return await res.json();
};

const getNode = async (nodeId: string, apiKey?: string): Promise<NodeWithEdges | null> => {
  const url = "nodes/" + nodeId;
  const res = await makeRequest({ url, method: "get" }, apiKey).catch((e) => null);
  if (!res.node) return null;
  return res as NodeWithEdges;
};

const getProduct = (id: number): Promise<NodeWithEdges> => makeRequest({ url: "nodes/" + id, method: "get" });

const createEdge = (edge: Omit<Edge, "id">): Promise<Edge> =>
  makeRequest({ url: "edges/", method: "post", body: edge });

const deleteEdge = (id: number): Promise<boolean> => makeRequest({ url: "edges/" + id, method: "delete" });

const createProduct = (attributes: Attributes): Promise<Node> =>
  makeRequest({
    url: "nodes/",
    method: "post",
    body: attributes,
  });

const updateProduct = (id: number, attributes: Attributes): Promise<boolean> =>
  makeRequest({
    url: "nodes/" + id,
    method: "put",
    body: attributes,
  });

const deleteProduct = (id: string): Promise<boolean> => makeRequest({ url: "nodes/" + id, method: "delete" });

const getCompanyNodeAssociations = async (
  query: {
    nodeId?: number;
    companyId?: number;
  },
  { limit, offset }: Pagination
): Promise<CompanyNodeAssociation[]> => {
  const { nodeId, companyId } = query;
  let url = `company-node-associations/?limit=${limit}&skip=${offset}`;

  if (nodeId) url += "&node_id=" + nodeId;
  if (companyId) url += "&company_id=" + companyId;

  const res = await makeRequest({
    url: url,
    method: "get",
  });
  return res;
};

const createNodeAssociation = async (nodeId: number, companyId: number): Promise<CompanyNodeAssociation[]> =>
  await makeRequest({
    url: "company-node-associations/",
    method: "post",
    body: [{ node_id: nodeId, company_id: companyId }],
  });

const deleteNodeAssociation = async (nodeId: number, companyId: number): Promise<boolean> =>
  await makeRequest({
    url: "company-node-associations/",
    method: "delete",
    body: [{ node_id: nodeId, company_id: companyId }],
  });

const createEdgeAssociation = async (edgeId: number, companyId: number): Promise<CompanyEdgeAssociation[]> =>
  await makeRequest({
    url: "company-edge-associations/",
    method: "post",
    body: [{ edge_id: edgeId, company_id: companyId }],
  });

const deleteEdgeAssociation = async (edgeId: number, companyId: number): Promise<CompanyEdgeAssociation[]> =>
  await makeRequest({
    url: "company-edge-associations/",
    method: "delete",
    body: [{ edge_id: edgeId, company_id: companyId }],
  });

const makeRequest = async ({ url, method, body }: RequestParams, apiKey?: string) => {
  if (!apiKey) apiKey = getGlobalKey().key;

  const requestBody: RequestInit = {
    headers: _getHeaders(),
    method,
  };

  if (body) {
    requestBody.body = JSON.stringify(body);
  }

  const paramSymbol = url.includes("?") ? "&" : "?";

  const res = await fetch(pdbBaseURL + url + paramSymbol + "api_key=" + apiKey, requestBody);
  const response = await res.json();
  return response;
};

export const ProductDatabaseConnection = {
  getCompanies,
  getApiKeys,
  getWhitelistedAttributes,
  putWhitelistedAttributes,
  getUniqueAttributeNames,
  getNodes,
  getNode,
  createEdge,
  deleteEdge,
  createProduct,
  updateProduct,
  deleteProduct,
  getCompanyNodeAssociations,
  createNodeAssociation,
  deleteNodeAssociation,
  createEdgeAssociation,
  deleteEdgeAssociation,
  getSimpleProductListByManufacturerArticleNumber,
  getProduct,
};
