import React from "react";
import { BaseProps } from "../core/baseTypes";
import styles from "./Label.module.css";

interface LabelProps extends BaseProps {
  htmlFor: string;
}

const Label = ({ children, htmlFor }: LabelProps): JSX.Element => {
  return (
    <label className={styles.label} htmlFor={htmlFor}>
      {children}
    </label>
  );
};

export default Label;
