import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PaginatedList from "../components/list/PaginatedList";
import Loading from "../components/loading/Loading";
import { PageContainer } from "../components/page/PageContainer";
import PageHeader from "../components/page/PageHeader";
import Heading from "../components/typography/Heading";
import usePaginatedAPIQueryWithAnnotatedVisibleItems from "../hooks/paginatedAPIQueryWithAnnotatedVisibleItemsHook";
import useDelayedFunction from "../hooks/DelayedHook";
import { useLoadingState } from "../hooks/LoadingHook";
import { asLimitAndOffset, getPageFromSearch } from "../hooks/PaginationHook";
import { getQueryFromSearch } from "../hooks/paginatedAPIQuery";
import { Company } from "../interfaces";
import { isValidSearchString } from "../lib/searchQuery";
import { getGlobalKey } from "../StateManager";
import CompanyNodeItem from "./CompanyNodeItem";
import { ProductDatabaseConnection } from "./ProductDatabaseConnection";
import SearchField from "./SearchField";

const IfNotLoading = ({ isLoading, children }: { isLoading: boolean; children: React.ReactNode }) =>
  isLoading ? <Loading /> : <>{children}</>;

export default function CompanyNodes({ company }: { company: Company }) {
  const [apiKey, setApiKey] = useState<string>();
  const { loadingState, setIsLoading, setIsLoaded, setLoadingError } = useLoadingState();

  useEffect(() => {
    setIsLoading();
    ProductDatabaseConnection.getApiKeys({ offset: 0, limit: 999 })
      .then((keys) => {
        const companyKeys = keys.filter((key) => key.company_id === company.id);
        if (companyKeys.length === 0) return setLoadingError("Company has no API keys");
        setApiKey(companyKeys[0].key);
      })
      .then(setIsLoaded)
      .catch(setLoadingError);
  }, []);

  if (loadingState.isError) return <>{loadingState.errorMessage}</>;
  if (!apiKey) return <></>; // This shouldn't be possible

  return <CompanyNodesWithLoadedAPIKey company={company} companyAPIKey={apiKey} />;
}

function CompanyNodesWithLoadedAPIKey({ company, companyAPIKey }: { company: Company; companyAPIKey: string }) {
  const loadCompanyPage = (page: number, pageSize: number, query: string) =>
    ProductDatabaseConnection.getNodes(query, asLimitAndOffset(page, pageSize), companyAPIKey);
  const loadCompletePage = (page: number, pageSize: number, query: string) =>
    ProductDatabaseConnection.getNodes(query, asLimitAndOffset(page, pageSize), getGlobalKey().key);

  const {
    loading: { loadingState, setIsLoading, setLoadingError },
    pageItems: nodes,
    pagination: { page, nextPage, prevPage },
    refresh,
    query: { query, setQuery: _setQuery },
  } = usePaginatedAPIQueryWithAnnotatedVisibleItems(
    loadCompletePage,
    loadCompanyPage,
    (x) => x.id,
    getPageFromSearch(window.location.search) ?? 1,
    25,
    getQueryFromSearch(window.location.search)
  );

  const setQuery = (searchString: string) => {
    if (searchString === "" || isValidSearchString(searchString)) _setQuery(searchString);
  };

  const history = useHistory();
  useEffect(() => {
    history.push(`?page=${page}&query=${query}`);
  }, [page, query]);

  const [searchString, setSearchString] = useState(query);

  const delayedSetQuery = useDelayedFunction<string>((x) => setQuery(x), 1000);

  const onUpdateSearchQuery = (newSearchString: string) => {
    setSearchString(newSearchString);
    delayedSetQuery(newSearchString);
  };

  const toggleNodeVisibility = (nodeId: number, isVisible: boolean) => {
    setIsLoading();
    const promise: Promise<unknown> = isVisible
      ? ProductDatabaseConnection.deleteNodeAssociation(nodeId, company.id)
      : ProductDatabaseConnection.createNodeAssociation(nodeId, company.id);
    promise.then(refresh).catch(setLoadingError);
  };

  if (loadingState.isError) return <>{loadingState.errorMessage}</>;

  return (
    <PageContainer
      header={
        <>
          <PageHeader left={<Heading type="h1">{company.name} - Products</Heading>} />
          <PageHeader
            left={
              <>
                <SearchField
                  onSearch={() => setQuery(searchString)}
                  value={searchString}
                  onValueChanged={onUpdateSearchQuery}
                  placeholder="Search"
                />
                {!!isValidSearchString(searchString) || <>Invalid search</>}
              </>
            }
          />
        </>
      }
    >
      <IfNotLoading isLoading={loadingState.isLoading}>
        <PaginatedList _style="bordered" page={page} onNextPage={nextPage} onPrevPage={prevPage}>
          {nodes.map((node) => (
            <CompanyNodeItem
              key={node.id}
              isVisible={node.visible}
              toggleNodeVisibility={toggleNodeVisibility}
              node={node}
              apiKey={companyAPIKey}
              companyId={company.id}
            />
          ))}
        </PaginatedList>
      </IfNotLoading>
    </PageContainer>
  );
}
