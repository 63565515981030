export type JSONValue = string | number | boolean | null | JSONObject | JSONArray;
export interface JSONObject {
  [key: string]: JSONValue;
}
export type JSONArray = Array<JSONValue>;

export interface FilterType {
  OR?: Array<FilterType | string[]>;
  AND?: Array<FilterType | string[]>;
}

export interface Company {
  id: number;
  name: string;
}

export interface ApiKey {
  id: number;
  key: string;
  build_number: string;
  company_id: number;
}

export interface WhitelistedAttribute {
  attribute: string;
  company_id: number;
}

export type Attributes = { [key: string]: JSONValue };

export interface Node {
  id: number;
  attributes: Attributes;
}

export interface Edge {
  id: number;
  to: number;
  from: number;
  attributes: Attributes;
}

export interface FromEdge {
  id: number;
  attributes: Attributes;
  from: Node;
}
export interface ToEdge {
  id: number;
  attributes: Attributes;
  to: Node;
}

export type ToOrFromEdge = ToEdge | FromEdge;

export interface NodeWithEdges {
  node: Node;
  incoming_edges: FromEdge[];
  outgoing_edges: ToEdge[];
}

export interface Annotation {
  x: number;
  y: number;
  title: string;
  description?: string;
}

export interface CompanyNodeAssociation {
  company_id: number;
  node_id: number;
}

export interface CompanyEdgeAssociation {
  company_id: number;
  edge_id: number;
}

export interface Token {
  email: string;
  userId: number;
  iat: number;
  exp: number;
  iss: string;
}

export interface User {
  token: string;
  isAdmin: boolean;
}

export interface RequestParams {
  url: string;
  method: "get" | "post" | "put" | "delete";
  body?: unknown;
}

export interface Preference {
  id: number;
  service: string;
  key: string;
  value: string;
}

export enum ButtonType {
  button = "button",
  reset = "reset",
  submit = "submit",
}

export enum FlashMessageType {
  positive = "positive",
  negative = "negative",
  info = "info",
}

export type dynamicOnChangeType = (attributeIndex: number, inputIndex: number, name: string, value: string) => void;

export type onChangeType = (name: string, value: string) => void;

export type SOUPVideoStatus = "commited" | "staged";

export interface SDMFile {
  id: number;
  name: string;
  data: {
    status: boolean;
    container: string;
  };
}

export interface SDMTake {
  name: string;
  data: {
    staged: boolean;
    type: string;
  };
  files: SDMFile[];
}

export class HTTPError {
  readonly _kind = "error";
  message: string;
  code: number;

  constructor(message: string, code: number) {
    this.message = message;
    this.code = code;
  }
}

export class HTTPSuccess<T> {
  readonly _kind = "success";
  constructor(public result: T) {}
}

export type HTTPResult<T> = HTTPSuccess<T> | HTTPError;
