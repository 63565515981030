import { authBaseURL } from "../help";
import { Preference, User } from "../interfaces";
import { setGlobalKey } from "../StateManager";

export async function login(email: string, password: string): Promise<User | null> {
  const authEndpoint = new URL(`/api/login`, authBaseURL).href;
  const prefEndpoint = new URL(`/api/preference?service=App&key=api_key`, authBaseURL).href;

  const response = await fetch(authEndpoint, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
    }),
  });

  if (response.ok) {
    const user = (await response.json()) as User;

    setGlobalKey("");

    const prefResponse = await fetch(prefEndpoint, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    const prefs = (await prefResponse.json()) as Preference[];

    if (prefs.length) {
      setGlobalKey(prefs[0].value);
    }

    return user;
  } else {
    return null;
  }
}
