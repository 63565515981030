import React from "react";
import ModalPage, { GenericModalProps } from "../components/modal/ModalPage";
import ProductInspectCard from "./ProductInspectCard";

interface ShowProductProps extends GenericModalProps {
  id: number;
}

const ShowProduct = ({ id, routeProps }: ShowProductProps): JSX.Element => {
  return (
    <ModalPage routeProps={routeProps} title={`Showing product ${id}`}>
      <ProductInspectCard id={id} />
    </ModalPage>
  );
};

export default ShowProduct;
