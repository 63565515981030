import React from "react";
import styles from "./Heading.module.css";
type HeadingType = "h1" | "h2" | "h3" | "h4" | "h5";

interface HeadingProps {
  type: HeadingType;
  children: React.ReactNode;
  align?: "left" | "center" | "right";
}

const Heading = ({ type, children, align = "left" }: HeadingProps): JSX.Element => {
  return React.createElement(type, { className: `${styles.heading} ${styles[align]}` }, children);
};

export default Heading;
