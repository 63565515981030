import React from "react";
import { Button } from "../button/Button";
import { ButtonGroup } from "../button/ButtonGroup";
import List, { ListProps } from "./List";

interface PaginationProps {
  page: number;
  onPrevPage: () => void;
  onNextPage: () => void;
}

interface PaginatedListProps extends ListProps, PaginationProps {}

function PaginationControls({ page, onNextPage, onPrevPage }: PaginationProps) {
  return (
    <ButtonGroup align="right">
      <Button variant="secondary" onClick={onPrevPage} icon="chevron_left"></Button>
      Page: {page}
      <Button variant="secondary" onClick={onNextPage} icon="chevron_right"></Button>
    </ButtonGroup>
  );
}

export default function PaginatedList(props: PaginatedListProps) {
  return (
    <>
      <List _style={props._style}>{props.children}</List>
      <PaginationControls {...props} />
    </>
  );
}
