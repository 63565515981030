import React, { Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { Button } from "../components/button/Button";
import { ButtonGroup } from "../components/button/ButtonGroup";
import Expandable from "../components/expandable/Expandable";
import Field from "../components/field/Field";
import FieldItem from "../components/field/FieldItem";
import FlashMessage from "../components/flashMessage/FlashMessage";
import SubHeader from "../components/header/SubHeader";
import Input from "../components/input/Input";
import Item from "../components/item/Item";
import Label from "../components/label/Label";
import List from "../components/list/List";
import ListItem from "../components/list/ListItem";
import ListItemActions from "../components/list/ListItemActions";
import Loading from "../components/loading/Loading";
import { PageContainer } from "../components/page/PageContainer";
import PageHeader from "../components/page/PageHeader";
import Heading from "../components/typography/Heading";
import {
  Attributes,
  CompanyNodeAssociation,
  FlashMessageType,
  JSONValue,
  NodeWithEdges,
  ToOrFromEdge,
} from "../interfaces";
import { attributeValueToString, getStringValue, hasValue } from "../lib/node";
import Setting from "../lib/setting";
import Annotator from "./annotator/Annotator";
import AttributeContainer from "./AttributeContainer";
import { ProductDatabaseConnection } from "./ProductDatabaseConnection";
import { RouteType } from "./ProductRouter";

interface stateInterface {
  loading: boolean;
  /** Node that we are currently editing, or null of we are creating a new one */
  nodeWithEdges?: NodeWithEdges;
  /** Attributes that we are editing/creating */
  attributes: { key: string; value: JSONValue }[];
  companyAssociations: CompanyNodeAssociation[] | null;
  submitResponse: FlashMessageType | null;
  createdProductId: number;
  id: string;
}

let onCreatedEdgeListeners: Array<(edge: ToOrFromEdge) => void> = [];

export const setCreatedEdgeListeners = (functions: Array<(edge: ToOrFromEdge) => void>): void => {
  onCreatedEdgeListeners = functions;
};

export const emitCreatedEdgeEvent = (edge: ToOrFromEdge): void => {
  onCreatedEdgeListeners.forEach((func) => {
    func(edge);
  });
};

class EditProduct extends React.Component<RouteType, stateInterface> {
  constructor(props: RouteType) {
    super(props);

    if (props.match.params.id && !/^\d+$/.test(props.match.params.id)) {
      props.history.replace("/error");
    }

    if (props.match.params.id) {
      document.title = `Editing product - ${props.match.params.id} | PDB Navigator`;
    } else {
      document.title = `Creating product | PDB Navigator`;
    }

    setCreatedEdgeListeners([this.addEdge]);

    this.state = {
      loading: true,
      nodeWithEdges: undefined,
      attributes: [],
      companyAssociations: null,
      submitResponse: null,
      createdProductId: 0,
      id: props.match.params.id,
    };

    this.addEdge = this.addEdge.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addInputPair = this.addInputPair.bind(this);
    this.addInput = this.addInput.bind(this);
    this.addAnnotation = this.addAnnotation.bind(this);
    this.deleteAnnotation = this.deleteAnnotation.bind(this);
    this.handleAnnotationChange = this.handleAnnotationChange.bind(this);
    this.removeInput = this.removeInput.bind(this);
    this.renderCollapsibles = this.renderCollapsibles.bind(this);
  }

  fetchProduct(): void {
    if (this.state.id) {
      ProductDatabaseConnection.getProduct(parseInt(this.state.id))
        .then((product) => {
          this.setState({
            nodeWithEdges: product,
            attributes: Object.entries(product.node.attributes).map(([key, value]) => ({ key, value })),
            createdProductId: 0,
            loading: false,
          });
        })
        .catch(() => {
          this.props.history.replace("/error");
        });
      ProductDatabaseConnection.getCompanyNodeAssociations(
        { nodeId: parseInt(this.state.id) },
        { limit: 999, offset: 0 }
      )
        .then((companyAssociations) => this.setState({ companyAssociations }))
        .catch(console.warn);
    } else {
      this.setState(() => {
        const setting = new Setting("article_number_key");

        return {
          attributes: [{ key: setting.getStoredValue(), value: "" }],
          loading: false,
        };
      });
    }
  }

  componentDidUpdate(prevProps: RouteType, prevState: stateInterface): void {
    if (this.state.id && prevState.id !== this.state.id) {
      this.fetchProduct();
    }
  }

  componentDidMount(): void {
    this.fetchProduct();
  }

  handleSubmit(event: React.FormEvent): void {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const initialValue: Attributes = {};
    const newAttributes = this.state.attributes.reduce((acc, { key, value }) => {
      // make sure we don't send arrays of length 1 - convert to string
      if (Array.isArray(value) && value.length === 1) {
        value = value[0];
      }
      return {
        ...acc,
        [key]: value,
      };
    }, initialValue);

    if (this.state.nodeWithEdges) {
      const promises = [];
      promises.push(ProductDatabaseConnection.updateProduct(this.state.nodeWithEdges.node.id, newAttributes));

      // TODO this functionality doesn't work
      this.state.nodeWithEdges.incoming_edges.forEach((edge) => {
        promises.push(ProductDatabaseConnection.updateProduct(edge.from.id, edge.from.attributes));
      });

      Promise.all(promises)
        .then(() => {
          this.setState({
            loading: false,
            submitResponse: FlashMessageType.positive,
          });
        })
        .catch(() => {
          this.setState({
            loading: false,
            submitResponse: FlashMessageType.negative,
          });
        });
    } else {
      // TODO can't we just redirect directly after save?
      ProductDatabaseConnection.createProduct(newAttributes)
        .then((response) => {
          this.setState({
            createdProductId: response.id,
            id: response.id.toString(),
            loading: false,
          });
        })
        .catch(() => this.setState({ submitResponse: FlashMessageType.negative }));
    }

    setTimeout(() => {
      this.setState({
        submitResponse: null,
      });
    }, 2000);
  }

  handleChange(attributeIndex: number, inputIndex: number, name: string, value: string): void {
    this.setState((prevState) => {
      const updated = [...prevState.attributes];
      if (name === "key") updated[attributeIndex].key = value;
      else {
        let oldVal = updated[attributeIndex].value;
        if (Array.isArray(oldVal)) oldVal[inputIndex] = value;
        else oldVal = value;
        updated[attributeIndex].value = oldVal;
      }
      return {
        attributes: updated,
      };
    });
  }

  handleAnnotationChange(annotationEdgeId: number, annotationIndex: number, name: string, value: string): void {
    this.setState((prevState) => {
      if (!prevState.nodeWithEdges) return {};
      const updated = [...prevState.nodeWithEdges.incoming_edges];

      const edge = updated.find((edge) => edge.id === annotationEdgeId);

      if (edge === undefined) return {};
      if (!hasValue("annotations", edge.from.attributes)) return {};
      if (!Array.isArray(edge.from.attributes["annotations"])) return {};

      const annotation = edge.from.attributes.annotations[annotationIndex];
      if (!Array.isArray(annotation)) return {};

      if (name === "title") {
        annotation[0] = value;
      } else if (name === "description") {
        annotation[3] = value;
      }

      return {
        ...prevState,
        nodeWithEdges: {
          ...prevState.nodeWithEdges,
          incoming_edges: updated,
        },
      };
    });
  }

  addInputPair(): void {
    this.setState((prevState) => ({
      attributes: [...prevState.attributes, { key: "", value: "" }],
    }));
  }

  // Add input to existing input pair
  addInput(index: number, value?: string): void {
    this.setState((prevState) => {
      const attributes = [...prevState.attributes];
      const attribute = { ...attributes[index] };

      if (attribute.value === "" && value) {
        // when adding a new input with upload, we want to remove the 1st empty value.
        attribute.value = value;
        attributes[index] = attribute;
        return { attributes };
      }

      if (!Array.isArray(attribute.value)) {
        attribute.value = [attribute.value];
      } else {
        attribute.value = [...attribute.value];
      }

      attribute.value.push(value ?? "");
      attributes[index] = attribute;

      return { attributes };
    });
  }

  removeInput(attributeIndex: number, inputIndex: number): void {
    this.setState((prevState) => {
      const attributes = [...prevState.attributes];
      const { key, value } = attributes[attributeIndex];

      if (Array.isArray(value) && value.length > 2) {
        // we have more than 2 inputs, just delete item at inputIndex
        attributes[attributeIndex] = { key, value: value.filter((_, i) => i !== inputIndex) };
      } else if (Array.isArray(value) && value.length === 2) {
        // we have 2 inputs, just keep the first value as a scalar
        attributes[attributeIndex] = { key, value: value[0] };
      } else {
        // this is the only input, remove the attribute altogether
        attributes.splice(attributeIndex, 1);
      }

      return { attributes };
    });
  }

  addAnnotation(edge_id: number, x: number, y: number): void {
    if (!this.state.nodeWithEdges) return;
    const edge = this.state.nodeWithEdges.incoming_edges.find((edge) => edge.id === edge_id);

    if (edge === undefined) return;
    if (!hasValue("annotations", edge.from.attributes)) return;
    if (!Array.isArray(edge.from.attributes["annotations"])) return;

    const title = (edge.from.attributes.annotations.length + 1).toString();

    edge.from.attributes.annotations.push([title, x, y]);
    this.setState({});
  }

  deleteAnnotation(edge_id: number, annotation_index: number): void {
    if (!this.state.nodeWithEdges) return;

    const edge = this.state.nodeWithEdges.incoming_edges.find((edge) => edge.id === edge_id);

    if (edge === undefined) return;
    if (!hasValue("annotations", edge.from.attributes)) return;
    if (!Array.isArray(edge.from.attributes["annotations"])) return;

    edge.from.attributes.annotations.splice(annotation_index, 1);
    this.setState({});
  }

  addEdge = (edge: ToOrFromEdge): void => {
    if (!this.state.nodeWithEdges) return;

    const nodeElem = { ...this.state.nodeWithEdges };
    if ("from" in edge) {
      nodeElem.incoming_edges.push({
        attributes: edge.attributes,
        from: edge.from,
        id: edge.id,
      });
    } else if ("to" in edge) {
      nodeElem.outgoing_edges.push({
        attributes: edge.attributes,
        to: edge.to,
        id: edge.id,
      });
    }

    this.setState({
      nodeWithEdges: {
        ...nodeElem,
        incoming_edges: nodeElem.incoming_edges,
        outgoing_edges: nodeElem.outgoing_edges,
      },
    });
  };

  renderCollabsiblesExistingNode(highestIndex: number): JSX.Element {
    if (!this.state.nodeWithEdges) return <></>;
    const annotations = this.state.nodeWithEdges.incoming_edges.filter((incoming_edge) =>
      hasValue("annotations", incoming_edge.from.attributes)
    );

    const AnnotatorElement = (
      <Item>
        <Annotator
          key={highestIndex + 1}
          annotationEdges={annotations}
          addAnnotation={this.addAnnotation}
          deleteAnnotation={this.deleteAnnotation}
          handleAnnotationChange={this.handleAnnotationChange}
        />
      </Item>
    );

    const incomingEdges = this.state.nodeWithEdges.incoming_edges.filter(
      (incoming_edge) => !incoming_edge.from.attributes.annotations
    );

    const outgoingEdges = this.state.nodeWithEdges.outgoing_edges;

    const IncomingEdgesElement = (
      <Item>
        <SubHeader
          left={<Heading type="h2">Incoming edges</Heading>}
          right={
            <ButtonGroup align="right">
              <Link
                to={{
                  pathname: `${this.props.location.pathname}/create_edge?direction=to`,
                  state: {
                    background: this.props.location,
                  },
                }}
              >
                <Button variant="secondary" icon="add">
                  Add incoming edge
                </Button>
              </Link>
            </ButtonGroup>
          }
        />
        <List _style="alternating">
          {incomingEdges.map((edge, index) => {
            return <ListItem key={index}>{this.renderEdgeAttributes(edge, edge.from.id)}</ListItem>;
          })}
        </List>
      </Item>
    );

    const OutgoingEdgesElement = (
      <Item>
        <SubHeader
          left={<Heading type="h2">Outgoing edges</Heading>}
          right={
            <ButtonGroup align="right">
              <Link
                to={{
                  pathname: `${this.props.location.pathname}/create_edge?direction=from`,
                  state: {
                    background: this.props.location,
                  },
                }}
              >
                <Button variant="secondary" icon="add">
                  Add outgoing edge
                </Button>
              </Link>
            </ButtonGroup>
          }
        />
        <List _style="alternating">
          {outgoingEdges.map((edge, index) => {
            return <ListItem key={index}>{this.renderEdgeAttributes(edge, edge.to.id)}</ListItem>;
          })}
        </List>
      </Item>
    );

    let CompanyAssociationsElement = null;
    if (this.state.companyAssociations) {
      CompanyAssociationsElement = (
        <Item>
          <SubHeader left={<Heading type="h2">Company associations</Heading>} />
          <List _style="alternating">
            {this.state.companyAssociations.sort().map((association, index) => (
              <ListItem key={index}>{association.company_id}</ListItem>
            ))}
          </List>
        </Item>
      );
    }
    return (
      <>
        {AnnotatorElement}
        {IncomingEdgesElement}
        {OutgoingEdgesElement}
        {CompanyAssociationsElement}
      </>
    );
  }

  renderCollapsibles(): JSX.Element {
    let highestIndex = 0;
    const collapsibles = this.state.attributes.map(({ key, value }, index) => {
      highestIndex = index;
      return (
        <ListItem key={index}>
          <AttributeContainer
            key={index}
            attributeKey={key}
            attributeValue={value}
            attributeIndex={index}
            handleChange={this.handleChange}
            addInput={this.addInput}
            removeInput={this.removeInput}
            nodeId={this.state.id}
          />
        </ListItem>
      );
    });

    return (
      <>
        <List _style={"alternating"}>{collapsibles}</List>
        {this.renderCollabsiblesExistingNode(highestIndex)}
      </>
    );
  }

  renderEdgeAttributes(edge: ToOrFromEdge, connected_node: number): JSX.Element {
    return (
      <Fragment>
        <Expandable
          content={
            <Fragment>
              {Object.entries(edge.attributes).map(([key, value], index) => (
                <Fragment key={index}>
                  <Field columns={2}>
                    <FieldItem>
                      <Label htmlFor="key">Key</Label>
                      <Input
                        name="key"
                        value={key}
                        placeholder="Key"
                        onChange={({ target: { value } }) => {
                          edge.attributes[value] = edge.attributes[key];
                          delete edge.attributes[key];
                          this.setState({});
                        }}
                      />
                    </FieldItem>
                    <FieldItem>
                      <Label htmlFor="value">Value</Label>
                      <Input
                        name="value"
                        value={attributeValueToString(value)}
                        placeholder="Value"
                        action={{
                          icon: "delete",
                          color: "red",
                          onAction: () => {
                            delete edge.attributes["key"];
                            this.setState({});
                          },
                        }}
                        onChange={({ target: { value } }) => {
                          edge.attributes[key] = value;
                          this.setState({});
                        }}
                      />
                    </FieldItem>
                  </Field>
                </Fragment>
              ))}
              <ButtonGroup align="left">
                <Button
                  type="button"
                  variant="positive"
                  icon="add"
                  onClick={() => {
                    edge.attributes[""] = "";
                    this.setState({});
                  }}
                >
                  New attribute
                </Button>
              </ButtonGroup>
            </Fragment>
          }
          collapsedContent={edge.id}
        />
        <ListItemActions>
          <ButtonGroup align="right">
            <Link
              to={{
                pathname: `/products/show/${connected_node}`,
                state: { background: this.props.location },
              }}
            >
              <Button variant="secondary" icon="visibility" size="small" />
            </Link>
            <Link to={`/products/edit/${connected_node}`} target="_blank">
              <Button variant="secondary" icon="open_in_new" size="small" />
            </Link>
            <Link
              to={{
                pathname: `/products/edit/${this.state.id}/delete_edge/${edge.id}`,
                state: { background: this.props.location },
              }}
            >
              <Button variant="secondary" icon="delete" size="small" />
            </Link>
          </ButtonGroup>
        </ListItemActions>
      </Fragment>
    );
  }

  render(): JSX.Element {
    if (this.state.createdProductId > 0) return <Redirect to={`/products/edit/${this.state.createdProductId}`} />;
    else if (this.state.loading) return <Loading />;
    else {
      const thumbnail = this.state.nodeWithEdges
        ? getStringValue("thumbnail", this.state.nodeWithEdges.node.attributes)
        : undefined;
      return (
        <form onSubmit={this.handleSubmit} method={this.state.id ? "put" : "post"}>
          <PageContainer
            header={
              <PageHeader
                left={
                  <>
                    {thumbnail && <img src={thumbnail} height={32} alt="" />}
                    <Heading type="h2">
                      {this.state.id ? `Editing product: ${this.state.id}` : "Creating new product"}
                    </Heading>
                  </>
                }
                right={
                  <ButtonGroup align="left">
                    <Button type="button" onClick={this.addInputPair} variant="secondary">
                      Add new attribute
                    </Button>
                    <Button type="submit" variant="positive">
                      Save
                    </Button>
                  </ButtonGroup>
                }
              />
            }
          >
            {this.state.submitResponse ? <FlashMessage type={this.state.submitResponse}>Saved!</FlashMessage> : null}
            <Item>
              <Heading type="h2">Attributes</Heading>
            </Item>

            {this.renderCollapsibles()}
          </PageContainer>
        </form>
      );
    }
  }
}

export default EditProduct;
