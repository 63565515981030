import React from "react";
import Input from "../components/input/Input";

interface propsInterface {
  value: string;
  onValueChanged: (value: string) => void;
  onSearch: () => void;
  placeholder: string;
}

export default function SearchField({ value, onValueChanged, onSearch, placeholder }: propsInterface) {
  return (
    <Input
      fluid={false}
      onChange={({ target: { value } }) => onValueChanged(value)}
      placeholder={placeholder}
      name=""
      value={value}
      action={{
        icon: "search",
        color: "gray",
        onAction: onSearch,
      }}
    />
  );
}
