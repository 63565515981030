import React from "react";
import { BaseProps } from "../core/baseTypes";
import styles from "./Item.module.css";

interface ItemProps extends BaseProps {
  width?: number;
}

const Item = ({ children, width }: ItemProps): JSX.Element => {
  return (
    <div className={styles.item} style={{ width }}>
      {children}
    </div>
  );
};

export default Item;
