import React from "react";
import { Annotation } from "../interfaces";

interface propInterface {
  annotations: Annotation[];
  url: string;
}

interface stateInterface {
  dims: Array<number>;
}

export default class AnnotatedImage extends React.Component<propInterface, stateInterface> {
  constructor(props: propInterface) {
    super(props);

    this.state = {
      dims: [512, 512],
    };

    const img = new Image();
    img.onload = () => {
      const height = (img.height * 512) / img.width;
      this.setState({
        dims: [512, height],
      });
    };
    img.src = this.props.url;
  }

  placeAnnotation(annotation: Annotation): JSX.Element {
    return (
      <div
        className="ui Annotation"
        style={{
          left: this.state.dims[0] * annotation.x,
          top: this.state.dims[1] * annotation.y,
        }}
        data-tooltip={annotation.description}
      >
        <div className="Annotation-title">{annotation.title}</div>
        <i className="dot"></i>
      </div>
    );
  }

  render(): JSX.Element {
    return (
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            backgroundImage: `url(${this.props.url})`,
            width: this.state.dims[0],
            height: this.state.dims[1],
          }}
          className="ui image centered annotatedImage"
        >
          {this.props.annotations.map((ann) => this.placeAnnotation(ann))}
        </div>
      </div>
    );
  }
}
