import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button } from "../components/button/Button";
import Field from "../components/field/Field";
import FlashMessage from "../components/flashMessage/FlashMessage";
import Input from "../components/input/Input";
import Item from "../components/item/Item";
import Label from "../components/label/Label";
import Heading from "../components/typography/Heading";
import wirevision_logo from "../images/wirevision_logo_text.svg";
import { FlashMessageType, User } from "../interfaces";
import { login } from "../lib/login";

interface propInterface {
  routeProps: RouteComponentProps;
  setUser: (value: User | null) => void;
}

const Login = ({ setUser }: propInterface): JSX.Element => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const user = await login(email, password);
    setLoading(false);
    if (user) setUser(user);
    else {
      setError(true);
    }
  };

  return (
    <form onSubmit={handleSubmit} method="post">
      <Item width={500}>
        {error && <FlashMessage type={FlashMessageType.negative}>Username and password did not match!</FlashMessage>}
        <Item>
          <Heading type="h1" align="center">
            <img src={wirevision_logo} alt="wirevision Logo" />
          </Heading>
        </Item>
        <Item>
          <Heading align="center" type="h1">
            Sign in
          </Heading>
        </Item>
        <Field>
          <Label htmlFor="email">Email</Label>
          <Input
            type="email"
            id="email"
            onChange={({ target: { value } }) => setEmail(value)}
            placeholder="your_email@example.com"
            name="email"
            value={email}
          />
        </Field>
        <Field>
          <Label htmlFor="password">Password</Label>
          <Input
            id="password"
            onChange={({ target: { value } }) => setPassword(value)}
            placeholder="*******"
            name="password"
            value={password}
            type="password"
          />
        </Field>
        <Field>
          <Button type="submit" variant="primary" fluid={true} loading={loading}>
            Sign in
          </Button>
        </Field>
      </Item>
    </form>
  );
};

export default Login;
