import React from "react";
import { useStyles } from "../core/useStyles";
import { Icon } from "../icon/Icon";
import styles from "./Checkbox.module.css";

interface CheckboxProps {
  checked: boolean;
  label: string;
  onClick: () => void;
}

const Checkbox = ({ checked, label, onClick }: CheckboxProps): JSX.Element => {
  const cx = useStyles(styles);

  return (
    <div
      className={cx({
        checkbox: [{ checked }],
      })}
      onClick={onClick}
    >
      {checked && <Icon name="check" />}
      <span className={styles.label}>{label}</span>
    </div>
  );
};

export default Checkbox;
