import React from "react";
import { Link, LinkProps } from "react-router-dom";
import styles from "./BasicLink.module.css";

const BasicLink = ({ to, children }: LinkProps): JSX.Element => {
  return (
    <Link to={to} className={styles.link}>
      {children}
    </Link>
  );
};

export default BasicLink;
