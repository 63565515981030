import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../components/button/Button";
import Item from "../components/item/Item";
import { PageContainer } from "../components/page/PageContainer";
import Heading from "../components/typography/Heading";

const NotFound = (): JSX.Element => {
  const history = useHistory();
  const handleGoBack = () => history.goBack();
  return (
    <PageContainer>
      <Item>
        <Heading type="h2">Oops, there's nothing here!</Heading>
        <p>
          We couldn't find the page you were looking for.{" "}
          <span role="img" aria-label="Sad emoji">
            🙁
          </span>
        </p>
      </Item>
      <Button onClick={handleGoBack} variant="primary">
        Take me back
      </Button>
    </PageContainer>
  );
};

export default NotFound;
