import { sdmBaseURL } from "../help";
import { HTTPError, HTTPSuccess, HTTPResult, SDMTake } from "../interfaces";

export default class SDMClient {
  static async getTakesForNodeID(nodeId: number): Promise<HTTPResult<SDMTake[]>> {
    const token = JSON.parse(localStorage.getItem("user") ?? '{"token": ""}')["token"];
    const res = await fetch(new URL(`/api/v2/soup/labels/${nodeId}/takes?includeFiles=true`, sdmBaseURL).href, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status !== 200) return new HTTPError(res.statusText, res.status);

    return new HTTPSuccess(await res.json());
  }
}
