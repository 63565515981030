export const getSearchKeys = (): Array<string> => [
  "",
  "annotations",
  "application",
  "applicator",
  "area mm^2",
  "article_number_aptiv",
  "article_number_aston_martin",
  "article_number_chs",
  "article_number_ellwee",
  "article_number_material_exchange",
  "article_number_te",
  "article_number_volvo",
  "article_number_wiretronic",
  "article_number_wiretronic_demo_company",
  "aston_mb_connector_id",
  "attributes_to_enforce",
  "case",
  "cavities",
  "coding",
  "colour",
  "connector_insertion_force",
  "content_type",
  "current",
  "date_accessed",
  "description",
  "group_name",
  "group_type",
  "height",
  "href",
  "hybrid",
  "images",
  "ingress_protection_code",
  "label",
  "length",
  "length mm",
  "manufacturer",
  "manufacturer_article_number",
  "manufacturer_product_url",
  "material",
  "mobivia_article_number",
  "number_of_rows",
  "operating_temperature",
  "operating_voltage",
  "pin size mm",
  "pitch",
  "plating",
  "property_enforce",
  "qx",
  "row_distance",
  "sealed",
  "sex",
  "terminal_article_number",
  "terminal_type",
  "thumbnail",
  "title",
  "tolerance mm",
  "type",
  "url",
  "vcc_terminal_article_number",
  "voltage",
  "waterproof",
  "weight",
  "width",
  "wire_size",
  "wiretronic_article_number",
];
