import { Annotation, JSONValue, Node } from "../interfaces";
import { Attributes } from "../interfaces";
import Setting from "./setting";

/** Type predicate function to determine if the value for the specified `key` in `attributes` is of type string */
export function hasStringValue<T extends string>(
  key: T,
  attributes: Attributes
): attributes is { [key in T]: string } & Attributes {
  return key in attributes && typeof attributes[key] === "string";
}

/** Type predicate function to determine if the value for the specified `key` exists in `attributes` and is not null */
export function hasValue<T extends string>(
  key: T,
  attributes: Attributes
): attributes is { [key in T]: NonNullable<JSONValue> } & Attributes {
  return key in attributes && attributes[key] !== null;
}

/** Returns the value for specified `key` in `attributes` if it is of type string, else undefined */
export function getStringValue(key: string, attributes: Attributes): string | undefined {
  return hasStringValue(key, attributes) ? attributes[key] : undefined;
}

/** Returns a string representation of the attribute value */
export function attributeValueToString(x: JSONValue): string {
  if (x === null) return "";
  if (typeof x === "boolean" || typeof x === "number" || typeof x === "string") return x + "";
  if (Array.isArray(x)) return x.map(attributeValueToString).join();
  return Object.keys(x)
    .map((y) => y + ": " + attributeValueToString(x[y]))
    .join();
}

/** Returns those attributes in a that aren't in b. Only looks at the top level keys */
export function attributesDifference(a: Attributes, b: Attributes): Attributes {
  const bKeys = Object.keys(b);
  const keysNotInB = Object.keys(a).filter((k) => !bKeys.includes(k));
  const newAttributes = Object.fromEntries(keysNotInB.map((k) => [k, a[k]]));
  return newAttributes;
}

/** Returns new attributes filtered by the predicate function */
export function filterAttributes(attributes: Attributes, predicate: (key: string) => boolean): Attributes {
  const newKeys = Object.keys(attributes).filter(predicate);
  return Object.fromEntries(newKeys.map((k) => [k, attributes[k]]));
}

/** Display some sort of title for the specified node using various heuristics  */
export function getTitle(node: Node) {
  // Use key specified in settings page
  const articleNumberKey = new Setting("article_number_key").getStoredValue();
  if (hasValue(articleNumberKey, node.attributes)) return node.attributes[articleNumberKey].toString();

  // If the node has a manufacturer_article_number, use that
  if (hasValue("manufacturer_article_number", node.attributes))
    return node.attributes["manufacturer_article_number"].toString();

  const titleCandidates = Object.keys(node.attributes).filter((k) => k.includes("article_number"));

  if (titleCandidates.length && hasValue(titleCandidates[0], node.attributes))
    // Otherwise use the first instance of an attribute containing the term "article_number"
    return node.attributes[titleCandidates[0]].toString();
  else if ("type" in node.attributes)
    return `${node.attributes["type"]} ${node.id}`; // Or use the type of the node if it exists (together with the id)
  else return node.id.toString(); // Or simply resort to the node id as a last way out
}

/** Parse the annotations attribute of a node according to predefined semantics */
export function parseAnnotations(annotationsAttribute: JSONValue): Annotation[] {
  if (!Array.isArray(annotationsAttribute)) {
    console.warn("Incorrect annotation attribute");
    return [];
  }

  // An actual annotation in PDB data is a 3-tuple or 4-tuple of string, number, number, (string)
  const isAnnotation = (x: JSONValue): x is [string, number, number] | [string, number, number, string] => {
    if (!Array.isArray(x) || x.length < 3 || x.length > 4) return false;
    if (typeof x[0] !== "string") return false;
    if (typeof x[1] !== "number") return false;
    if (typeof x[2] !== "number") return false;
    if (x.length === 4 && typeof x[3] !== "string") return false;
    return true;
  };

  return annotationsAttribute.filter(isAnnotation).map((elem) => ({
    title: elem[0],
    x: Number(elem[1]),
    y: Number(elem[2]),
    description: elem[3],
  }));
}
