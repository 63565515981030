import React from "react";
import styles from "./Select.module.css";

interface Option {
  value: string;
  label: string;
}
const Select = ({
  label,
  options,
  callback,
}: {
  label: string;
  options: Option[];
  callback: (opt: Option) => void;
}) => {
  return (
    <div className={styles.container}>
      <b style={{ float: "left", marginRight: 10 }}>{label}</b>
      <div className={styles.select}>
        <select
          onChange={(e) => {
            const opt = options.find((opt) => opt.value === e.target.value) ?? options[0];
            callback(opt);
          }}
        >
          {options.map((opt, i) => (
            <option value={opt.value} key={i}>
              {opt.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
export default Select;
