import React from "react";
import styles from "./Header.module.css";

interface SubHeaderProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
}

const SubHeader = ({ left, right }: SubHeaderProps): JSX.Element => {
  return (
    <div className={styles.subHeader}>
      <div className={styles.left}>{left}</div>
      <div className={styles.right}>{right}</div>
    </div>
  );
};

export default SubHeader;
