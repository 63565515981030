import React, { useEffect, useState } from "react";
import Checkbox from "../components/checkbox/Checkbox";
import List from "../components/list/List";
import ListItem from "../components/list/ListItem";
import ListItemActions from "../components/list/ListItemActions";
import Loading from "../components/loading/Loading";
import { PageContainer } from "../components/page/PageContainer";
import PageHeader from "../components/page/PageHeader";
import Heading from "../components/typography/Heading";
import { useLoadingState } from "../hooks/LoadingHook";
import { Company, WhitelistedAttribute } from "../interfaces";
import { ProductDatabaseConnection } from "./ProductDatabaseConnection";

const LoadingPage = ({ companyName }: { companyName: string }) => (
  <PageContainer header={<PageHeader left={<Heading type="h1">{companyName} - Whitelisted attributes</Heading>} />}>
    <Loading />
  </PageContainer>
);

export default function WhitelistedAttributes({ company }: { company: Company }) {
  const [whitelistedAttributes, setWhitelistedAttributes] = useState<WhitelistedAttribute[]>([]);
  const [existingAttributeNames, setExistingAttributeNames] = useState<string[]>([]);
  const { loadingState, setIsLoading, setIsLoaded, setLoadingError } = useLoadingState();

  useEffect(() => {
    setIsLoading();
    const loadingPromises = [
      ProductDatabaseConnection.getWhitelistedAttributes(company.id).then(setWhitelistedAttributes).catch(console.warn),
      ProductDatabaseConnection.getUniqueAttributeNames()
        .then((attributes) => attributes.filter((x) => x !== ""))
        .then(setExistingAttributeNames)
        .catch(console.warn),
    ];
    Promise.all(loadingPromises).then(setIsLoaded).catch(setLoadingError);
  }, [company]);

  const toggleWhiteListedAttribute = (attribute: string) => {
    setIsLoading();
    const newWhitelistedAttributes = !whitelistedAttributes.find((wa) => wa.attribute === attribute)
      ? [...whitelistedAttributes, { company_id: company.id, attribute }]
      : [...whitelistedAttributes.filter((wa) => wa.attribute !== attribute)];
    ProductDatabaseConnection.putWhitelistedAttributes(company.id, newWhitelistedAttributes)
      .then(setWhitelistedAttributes)
      .then(setIsLoaded)
      .catch(setLoadingError);
  };

  function AttributeListItem(attribute: string) {
    const isChecked = !!whitelistedAttributes.find((wa) => wa.attribute === attribute);
    return (
      <ListItem>
        <Heading type="h3">{attribute} </Heading>
        <ListItemActions>
          <Checkbox checked={isChecked} label={"Whitelisted"} onClick={() => toggleWhiteListedAttribute(attribute)} />
        </ListItemActions>
      </ListItem>
    );
  }

  if (loadingState.isError) return <>{loadingState.errorMessage}</>;
  if (loadingState.isLoading) return <LoadingPage companyName={company.name} />;

  return (
    <PageContainer header={<PageHeader left={<Heading type="h1">{company.name} - Whitelisted attributes</Heading>} />}>
      <List _style="bordered">{existingAttributeNames.map(AttributeListItem)}</List>
    </PageContainer>
  );
}
