import React from "react";
import { buttonVariants, sizes } from "../core/baseTypes";
import { useStyles } from "../core/useStyles";
import { Icon } from "../icon/Icon";
import styles from "./Button.module.css";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: sizes;
  variant?: buttonVariants;
  icon?: string;
  iconPosition?: "before" | "after";
  iconColor?: string;
  noPadding?: boolean;
  loading?: boolean;
  fluid?: boolean;
}

export const Button = ({
  onClick,
  size = "medium",
  variant = "primary",
  children = "",
  icon = "",
  iconPosition = "before",
  type = "button",
  iconColor = "",
  noPadding = false,
  loading = false,
  fluid = false,
  ...props
}: ButtonProps): JSX.Element => {
  const cx = useStyles(styles);
  const iconPositionClass = { before: "iconBefore", after: "iconAfter" }[iconPosition];

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (onClick && !loading && !props.disabled) {
      onClick(e);
    }
  };

  return (
    <button
      onClick={handleClick}
      className={cx({
        button: [
          size,
          variant,
          iconPositionClass,
          { iconOnly: children?.toString().length ? false : true },
          { noPadding: noPadding },
          { loading },
          { fluid },
        ],
      })}
      type={type}
      disabled={props.disabled}
    >
      {icon && iconPosition === "before" && <Icon name={icon} color={iconColor} />}
      {loading ? (
        <img className={styles.loadingSpinner} src="/oval.svg" alt="Loading..." />
      ) : (
        children && <span className={styles.buttonText}>{children}</span>
      )}
      {icon && iconPosition === "after" && <Icon name={icon} color={iconColor} />}
    </button>
  );
};
