import React from "react";
import { BaseProps } from "../core/baseTypes";
import { useStyles } from "../core/useStyles";
import styles from "./List.module.css";

export interface ListProps extends BaseProps {
  _style: "alternating" | "bordered" | "plain";
}

const List = ({ children, _style }: ListProps): JSX.Element => {
  const cx = useStyles(styles);
  return (
    <ul
      className={cx({
        list: [_style],
      })}
    >
      {children}
    </ul>
  );
};
export default List;
