import React, { ChangeEvent } from "react";
import { Button } from "../button/Button";
import { useStyles } from "../core/useStyles";
import styles from "./Input.module.css";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  fluid?: boolean;
  action?: Action | false;
}

interface Action {
  icon: string;
  color: string;
  onAction: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Input = ({ fluid = true, ...props }: InputProps): JSX.Element => {
  const handleAction = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (props.action) {
      props.action.onAction(event);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const cx = useStyles(styles);

  return (
    <div
      className={cx({
        input: [{ fluid }, { withButton: !!props.action }],
      })}
      style={{ width: props.width }}
    >
      <input
        type={props.type}
        autoComplete="off"
        onChange={handleChange}
        onKeyDown={props.onKeyDown}
        name={props.name}
        id={props.id}
        value={props.value}
        placeholder={props.placeholder}
      />
      {props.action && (
        <span className={styles.button}>
          <Button
            onClick={handleAction}
            variant="input_action"
            icon={props.action.icon}
            iconColor={props.action.color}
          ></Button>
        </span>
      )}
    </div>
  );
};

export default Input;
