import React, { Fragment } from "react";
import StyledExpandable from "../../components/expandable/StyledExpandable";
import Field from "../../components/field/Field";
import FieldItem from "../../components/field/FieldItem";
import Input from "../../components/input/Input";
import Item from "../../components/item/Item";
import Label from "../../components/label/Label";
import Heading from "../../components/typography/Heading";
import { dynamicOnChangeType, FromEdge } from "../../interfaces";
import { getStringValue, hasValue, parseAnnotations } from "../../lib/node";
import AnnotatorEditor from "./AnnotatorEditor";

interface propInterface {
  annotationEdges: FromEdge[];
  addAnnotation: (edge_id: number, x: number, y: number) => void;
  deleteAnnotation: (edge_id: number, annotation_index: number) => void;
  handleAnnotationChange: dynamicOnChangeType;
}

interface stateInterface {
  showImageURLInput: boolean;
  annotationEdges: FromEdge[];
  imageURLs: string[];
}

export default class Annotator extends React.Component<propInterface, stateInterface> {
  constructor(props: propInterface) {
    super(props);
    this.state = {
      showImageURLInput: false,
      annotationEdges: props.annotationEdges,
      imageURLs: props.annotationEdges
        .map((image) => getStringValue("url", image.from.attributes) ?? "")
        .filter((x) => x !== ""),
    };
  }

  toggleImageURLInput = (): void => {
    this.setState((prevState) => ({
      showImageURLInput: !prevState.showImageURLInput,
    }));
  };

  renderAnnotationEdge(edge: FromEdge, index: number) {
    const annotations = hasValue("annotations", edge.from.attributes)
      ? parseAnnotations(edge.from.attributes.annotations)
      : [];

    return (
      <Field key={index}>
        <StyledExpandable
          key={index}
          title={edge.from.attributes.label}
          initActive={false}
          content={
            <>
              <AnnotatorEditor
                edge_id={edge.id}
                url={getStringValue("url", edge.from.attributes) ?? ""}
                annotations={annotations}
                label={getStringValue("label", edge.from.attributes) ?? ""}
                onClick={(x, y) => this.props.addAnnotation(edge.id, x, y)}
                {...this.props}
              />
              <Item>
                <Field>
                  <Label htmlFor={`image_url_input_${edge.id}`}>Image URL</Label>
                  <Input
                    name="image_url_input"
                    id={`image_url_input_${edge.id}`}
                    placeholder="Image URL"
                    value={this.state.imageURLs[index]}
                    onChange={({ target: { value } }) => {
                      this.setState((prevState) => {
                        const newState = { ...prevState };
                        newState.imageURLs[index] = value;
                        return newState;
                      });
                    }}
                    action={{
                      icon: "check",
                      color: "green",
                      onAction: () => {
                        this.setState((prevState) => {
                          const newState = { ...prevState };
                          newState.annotationEdges[index].from.attributes.url = this.state.imageURLs[index];
                          return newState;
                        });
                      },
                    }}
                  />
                </Field>
              </Item>
              {annotations.map((annotation, j) => {
                return (
                  <Fragment key={j}>
                    <Field columns={2}>
                      <FieldItem>
                        <Label htmlFor={`input_title_${j}`}>Title</Label>
                        <Input
                          id={`input_title_${j}`}
                          name="title"
                          value={annotation.title ?? ""}
                          placeholder={"Title"}
                          onChange={({ target: { name, value } }) =>
                            this.props.handleAnnotationChange(edge.id, j, name, value)
                          }
                        />
                      </FieldItem>

                      <FieldItem>
                        <Label htmlFor={`input_description_${j}`}>Description</Label>
                        <Input
                          id={`input_description_${j}`}
                          name="description"
                          value={annotation.description ?? ""}
                          placeholder={"Description"}
                          onChange={({ target: { name, value } }) =>
                            this.props.handleAnnotationChange(edge.id, j, name, value)
                          }
                          action={{
                            icon: "delete",
                            color: "red",
                            onAction: () => this.props.deleteAnnotation(edge.id, j),
                          }}
                        />
                      </FieldItem>
                    </Field>
                  </Fragment>
                );
              })}
            </>
          }
        />
      </Field>
    );
  }

  render(): JSX.Element {
    if (!this.props.annotationEdges.length) return <></>;

    return (
      <>
        <Item>
          <Heading type="h2">Annotations</Heading>
        </Item>
        {this.state.annotationEdges.map((image, index) => this.renderAnnotationEdge(image, index))}
      </>
    );
  }
}
