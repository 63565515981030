import { useState } from "react";

export function getPageFromSearch(search: string): number | null {
  const urlSearchParams = new URLSearchParams(search);
  const urlPage = urlSearchParams.get("page");
  return urlPage ? parseInt(urlPage) : null;
}

export function asLimitAndOffset(page: number, pageSize: number) {
  return { offset: pageSize * (page - 1), limit: pageSize };
}

export default function usePagination({
  initialPage,
  initialPageSize,
}: {
  initialPageSize: number;
  initialPage: number;
}) {
  const [page, _setPage] = useState(initialPage);
  const [pageSize] = useState(initialPageSize);

  // bound page from below at 1
  function setPage(x: React.SetStateAction<number>) {
    if (typeof x === "function") {
      _setPage((oldState) => Math.max(1, x(oldState)));
    } else {
      _setPage(Math.max(1, x));
    }
  }

  const prevPage = () => setPage((oldPage) => oldPage - 1);
  const nextPage = () => setPage((oldPage) => oldPage + 1);

  return { page, pageSize, setPage, prevPage, nextPage };
}
