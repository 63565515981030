import React from "react";
import { Icon } from "../icon/Icon";
import styles from "./Help.module.css";

interface HelpProps {
  text: string;
}

const Help = ({ text }: HelpProps): JSX.Element => {
  return (
    <span data-tooltip={text} className={styles.help}>
      <Icon name="help" />
    </span>
  );
};

export default Help;
