import React from "react";
import { BaseProps } from "../core/baseTypes";
import { Icon } from "../icon/Icon";
import styles from "./List.module.css";

interface ListItemProps extends BaseProps {
  image?: {
    url?: string;
  };
}

const ListItem = ({ image = undefined, children }: ListItemProps): JSX.Element => {
  const renderImage = () => {
    if (image && image.url) {
      return <img src={image.url} className={styles.image} alt="List item" />;
    } else if (image) {
      return (
        <div className={styles.imagePlaceholder}>
          <Icon name="image" />
        </div>
      );
    }
  };
  return (
    <li className={styles.listItem}>
      {renderImage()}
      {children}
    </li>
  );
};

export default ListItem;
