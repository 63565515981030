// Does not consider string beginning with www to be a valid url.
// URL scheme is required
export default function isValidURL(maybeURL: string): boolean {
  let url;

  try {
    url = new URL(maybeURL);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}
