export type settingValues = {
  name: string;
  label: string;
  placeholder: string;
  defaultValue: string;
  helpText: string;
};

export type settingType = {
  [key: string]: settingValues;
};

export const settings: settingType = {
  article_number_key: {
    name: "article_number_key",
    defaultValue: "manufacturer_article_number",
    placeholder: "Article number key",
    label: "Article number key",
    helpText: "What to look for when searching for a product",
  },
  api_key: {
    name: "api_key",
    defaultValue: "",
    placeholder: "PDB API key",
    label: "PDB API key",
    helpText: "The API key used to make requests to the database",
  },
  autocomplete_keys: {
    name: "autocomplete_keys",
    defaultValue: "",
    placeholder: "Autocomplete keys, separated by commas",
    label: "Autocomplete keys",
    helpText: "Autocomplete suggestions for an attributes key",
  },
};

type allSettings = Record<string, Setting>;

class Setting {
  public readonly name: string;
  public readonly placeholder: string;
  public readonly label: string;
  public readonly defaultValue: string;
  public readonly helpText: string;
  public value: string;

  constructor(key: string) {
    this.name = settings[key].name;
    this.placeholder = settings[key].placeholder;
    this.label = settings[key].label;
    this.defaultValue = settings[key].defaultValue;
    this.helpText = settings[key].helpText;
    this.value = this.getStoredValue();
  }

  public static getAll = (): allSettings => {
    const ret: allSettings = {};
    Object.keys(settings)
      .map((settingKey) => new Setting(settingKey))
      .forEach((setting: Setting) => {
        ret[setting.name] = setting;
      });

    return ret;
  };

  getStoredValue = (): string => {
    let value = localStorage.getItem(this.name);
    if (value === null || value === "") {
      value = this.defaultValue;
    }
    return value;
  };

  setValue = (value: string): void => {
    localStorage.setItem(this.name, value);
  };
}

export default Setting;
