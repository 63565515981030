import React from "react";

interface IconProps {
  name: string;
  size?: "s" | "m" | "l" | "xl";
  color?: string;
}

const sizeObject = {
  s: "md-18",
  m: "md-24",
  l: "md-36",
  xl: "md-48",
};

export const Icon = ({ name, size = "s", color = "inherit" }: IconProps): JSX.Element => {
  return (
    <span className={`material-icons ${sizeObject[size]}`} style={{ color: color }}>
      {name}
    </span>
  );
};
