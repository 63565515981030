import React from "react";
import { useStyles } from "../core/useStyles";
import styles from "./Button.module.css";

interface ButtonGroupProps {
  children: React.ReactNode;
  align: "left" | "right" | "center";
}

export const ButtonGroup = ({ children, align = "left" }: ButtonGroupProps): JSX.Element => {
  const cx = useStyles(styles);
  return (
    <div
      className={cx({
        buttonGroup: [align],
      })}
    >
      {children}
    </div>
  );
};
