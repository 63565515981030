import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { RouteType } from "../../modules/ProductRouter";
import { Button } from "../button/Button";
import Heading from "../typography/Heading";
import styles from "./Modal.module.css";

export interface GenericModalProps {
  routeProps: RouteType;
}

interface ModalProps extends GenericModalProps {
  children: React.ReactNode;
  title?: string;
}

const NewModal = ({ children, routeProps, title = "" }: ModalProps): JSX.Element | null => {
  const modalRef = useRef<HTMLDivElement>(null);

  const background = routeProps.location.state && routeProps.location.state.background;

  const modalBackground = (background && `${background.pathname}${background.search}`) || "/products";

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!(e.target instanceof HTMLElement && modalRef.current?.contains(e.target))) {
      close();
    }
  };

  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      close();
    }
  });

  const close = () => {
    document.body.style.overflow = "initial";
    routeProps.history.push(modalBackground);
  };

  document.body.style.overflow = "hidden";
  return ReactDOM.createPortal(
    <div className={styles.modalBackground}>
      <div className={styles.modalWrapper} onMouseDown={handleOutsideClick}>
        <div className={styles.modal} ref={modalRef}>
          <div className={styles.modalHeader}>
            <Heading type="h1">{title}</Heading>
            <Button variant="ghost" icon="close" onClick={() => close()}></Button>
          </div>
          <div className={styles.modalContent}>{children}</div>
        </div>
      </div>
      ,
    </div>,
    document.body
  );
};
export default NewModal;
