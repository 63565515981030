import React, { Component } from "react";
import { Button } from "../components/button/Button";
import Field from "../components/field/Field";
import FlashMessage from "../components/flashMessage/FlashMessage";
import Help from "../components/help/Help";
import Input from "../components/input/Input";
import Label from "../components/label/Label";
import { PageContainer } from "../components/page/PageContainer";
import PageHeader from "../components/page/PageHeader";
import Heading from "../components/typography/Heading";
import { FlashMessageType } from "../interfaces";
import Setting from "../lib/setting";

type SettingsState = {
  submitResponse: FlashMessageType | null;
  settings: Record<string, Setting>;
};

export default class Settings extends Component<unknown, SettingsState> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      settings: Setting.getAll(),
      submitResponse: null,
    };
    document.title = "Settings | PDB Navigator";
  }

  handleSettingsSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    Object.values(this.state.settings).forEach((setting) => {
      setting.setValue(setting.value);
    });
    this.setState({
      submitResponse: FlashMessageType.positive,
    });
    setTimeout(() => {
      this.setState({
        submitResponse: null,
      });
    }, 2000);
  };

  handleChange = (name: string, value: string): void => {
    this.setState({
      settings: {
        ...this.state.settings,
        [name]: {
          ...this.state.settings[name],
          value,
        },
      },
    });
  };

  render(): JSX.Element {
    return (
      <PageContainer header={<PageHeader left={<Heading type="h1">Settings</Heading>} />}>
        <form onSubmit={this.handleSettingsSubmit}>
          {this.state.submitResponse ? <FlashMessage type={this.state.submitResponse}>Saved!</FlashMessage> : null}
          {Object.values(this.state.settings).map((setting: Setting) => {
            return (
              <Field>
                <Label htmlFor={setting.name}>
                  {setting.label}
                  <Help text={setting.helpText} />
                </Label>
                <Input
                  placeholder={setting.placeholder}
                  value={setting.value}
                  name={setting.name}
                  onChange={({ target: { name, value } }) => this.handleChange(name, value)}
                />
              </Field>
            );
          })}
          <Field>
            <Button type="submit" variant="positive">
              Save
            </Button>
          </Field>
        </form>
      </PageContainer>
    );
  }
}
