import React from "react";
import { FlashMessageType } from "../../interfaces";
import { useStyles } from "../core/useStyles";
import styles from "./FlashMessage.module.css";

interface FlashMessageProps {
  children: React.ReactNode;
  type: FlashMessageType;
}

const FlashMessage = (props: FlashMessageProps): JSX.Element => {
  const cx = useStyles(styles);
  return (
    <div
      className={cx({
        flashMessage: [props.type],
      })}
    >
      {props.children}
    </div>
  );
};

export default FlashMessage;
