import React, { ComponentType, useState } from "react";
import styles from "./Loading.module.css";

export function withLoading<T>(Component: ComponentType<T>) {
  return (props: Omit<T, "setLoading">): JSX.Element => {
    const [loading, setLoading] = useState(false);
    return (
      <>
        <Component {...(props as T)} setLoading={setLoading} />
        {loading && <Loading />}
      </>
    );
  };
}

const Loading = (): JSX.Element => {
  return (
    <div className={styles.loadingBackground}>
      <div className={styles.loadingIndicator}>
        <img src="/puff.svg" alt="Loading..." />
      </div>
    </div>
  );
};
export default Loading;
