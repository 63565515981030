import React from "react";
import { BaseProps } from "../core/baseTypes";
import Item from "../item/Item";
import styles from "./Page.module.css";

interface PageContainerProps extends BaseProps {
  header?: React.ReactNode;
  subheader?: React.ReactNode;
}

export const PageContainer = ({ header, subheader, children }: PageContainerProps): JSX.Element => {
  return (
    <div className={styles.pageContainer}>
      <Item>{header}</Item>
      {subheader}
      <div className={styles.inner}>{children}</div>
    </div>
  );
};
