interface styleProps {
  readonly [key: string]: string;
}

interface booleanStyle {
  [key: string]: boolean;
}

interface innerStyleProps {
  [key: string]: Array<string | booleanStyle>;
}

export const useStyles = (_styles: styleProps): ((classes: innerStyleProps) => string) => {
  const styles = _styles;
  return (classes: innerStyleProps): string => {
    return Object.keys(classes)
      .map((element) => {
        return classes[element]
          .map((cls) => {
            if (typeof cls === "string") {
              return styles[cls];
            } else {
              return Object.keys(cls)
                .map((cl) => {
                  if (cls[cl]) {
                    return styles[cl];
                  } else {
                    return "";
                  }
                })
                .join(" ");
            }
          })
          .join(" ")
          .concat(" ")
          .concat(styles[element]);
      })
      .join(" ");
  };
};
